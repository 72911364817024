import { Routes } from '@angular/router';

export const CreditsRoutes: Routes = [
      {
            path: 'listado',
            loadComponent: () => import('./pages/credits-list-index/credits-list-index.component')
      },
      {
            path: 'listado/:id',
            loadComponent: () => import('./pages/credits-list-index/credits-list-index.component')
      },
      {
            path: 'pago-detalles/:id',
            loadComponent: () => import('./pages/credits-payments-details-index/credits-payments-details-index.component')
      },
];
