<div class="row mb-2">
    <div class="col-8">
      <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Nombre del Cliente:</strong>
    </div>
    <div class="col-4">
      <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ customer().name }} / {{ customer().id }}</span>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-8">
      <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Solicitudes en Proceso:</strong>
    </div>
    <div class="col-4">
      <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totals().processPending }}
      </span>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-8">
      <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Solicitudes Rechazadas:</strong>
    </div>
    <div class="col-4">
      <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totals().reject }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <strong class="flex-shrink-0 mr-3" style="font-size: 15px">Créditos Activos:</strong>
    </div>
    <div class="col-4">
      <span class="flex-grow-1 pl-3" style="font-size: 15px">{{ totals().activeCredits }}
      </span>
    </div>
  </div>