<div class="modal-header">
        <h4 class="modal-title">
                {{ title }} Solicitud de credito #{{ creditRequestId }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
        <ul class="nav nav-tabs">
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'informacionPersonal'"
                                (click)="cambiarPestana('informacionPersonal')">
                                Información Personal
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'documentosAsociados'"
                                (click)="cambiarPestana('documentosAsociados')">
                                Documentos Asociados
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'historialCreditos'"
                                (click)="cambiarPestana('historialCreditos')">
                                Historial de Créditos
                        </a>
                </li>
                <li class="nav-item">
                        <a class="nav-link" [class.active]="tabActiva === 'informacionFinanciera'"
                                (click)="openFinancialInfoModal(customerInfo)">
                                Información Financiera
                        </a>
                </li>
        </ul>

        <div class="tab-content">
                <div [class]="tabActiva === 'informacionPersonal' ? 'tab-pane show active' : 'tab-pane'">
                        <app-personal-information-tab [customerInfo]="customerInfo" [creditRequestId]="creditRequestId"
                                [datosDeshabilitados]="datosDeshabilitados"></app-personal-information-tab>
                </div>
                <div [class]="tabActiva === 'documentosAsociados' ? 'tab-pane show active': 'tab-pane'">
                        <app-documents-tab [creditRequestId]="creditRequestId"
                                [customerInfo]="customerInfo"></app-documents-tab>
                </div>
                <div [class]="tabActiva === 'historialCreditos' ? 'tab-pane show active' : 'tab-pane'">
                        <app-credits-tab [customerInfo]="customerInfo"></app-credits-tab>
                </div>
        </div>
</div>