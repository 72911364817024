import { CommonModule, formatCurrency, } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditsRequestsCommentsComponent } from '../../../../../shared/components/modals/credits-requests-comments/credits-requests-comments.component';
import { ModalServiceAlert } from '../../../../../shared/components/modal-alert/service/modal-alert.service';
import { toggleLoader } from '../../../../../shared/helpers/functions-helper.service';
import { LoaderService } from '../../../../../shared/services/loader.service';
import { CreditRequestService } from '../../../services/credit-request.service';
import { CreditRequestCardService } from '../../../services/credit-request-card.service';
import { CreditRequestInterface } from '../../../interfaces/credits-request-interfaces';
import { CustomersService } from '../../../../customers/services/customers.service';
import { CustomerInfoShowModalComponent } from '../../../../../shared/components/modals/customer-info-show-modal/customer-info-show-modal.component';
import { DynamicFormModalComponent } from '../../../../../shared/components/modals/dynamic-form-modal/dynamic-form-modal.component';
import { PreaprovedNoteComponent } from '../preaproved-note/preaproved-note.component';
import { DashboardCollectionsService } from '../../../../dashboard-collections/services/dashboard-collections.service';

@Component({
      selector: 'app-credit-request-card',
      standalone: true,
      imports: [CommonModule, NgbTooltipModule],
      templateUrl: './credit-request-card.component.html',
      styleUrl: './credit-request-card.component.scss'
})
export class CreditRequestCardComponent {
      @Input() request: any;
      @Input() typeGroup!: string;

      constructor(
            private modalService: NgbModal,
            private cdr: ChangeDetectorRef,
            private alertService: ModalServiceAlert,
            private loaderService: LoaderService,
            private creditRequestService: CreditRequestService,
            private creditRequestCardService: CreditRequestCardService,
            private customerService: CustomersService,
            private dashboardCollectionsService: DashboardCollectionsService
      ) { }

      /**
       * Muestra un modal con la información del cliente.
       * @param {number} customerId - ID del cliente.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       */
      showModalInfoCustomer(customerId: number, creditRequestId: number): void {
            toggleLoader(this.loaderService, true, 'Cargando información del cliente...');
            this.customerService
                  .getCustomerInfo(customerId)
                  .subscribe((customerInfo) => {
                        const modalRef = this.modalService.open(
                              CustomerInfoShowModalComponent,
                              { size: 'xl' }
                        );

                        modalRef.componentInstance.title = 'Actualizar cliente.';
                        modalRef.componentInstance.customerInfo = customerInfo.customer;
                        modalRef.componentInstance.datosDeshabilitados = false;
                        modalRef.componentInstance.creditRequestId = creditRequestId;
                        this.cdr.detectChanges();
                        toggleLoader(this.loaderService, false);
                  });
      }

      /**
       * Muestra un modal con los comentarios de la solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       */
      showModalCreditRequestComments(creditRequestId: number) {
            const modalRef = this.modalService.open(CreditsRequestsCommentsComponent, { size: 'lg' });
            modalRef.componentInstance.creditRequestId = creditRequestId;
            this.cdr.detectChanges();
      }

      /**
       * Confirma la asignación de un crédito.
       * @param {number} requestId - ID de la solicitud de crédito.
       */
      confirmAssignment(requestId: number) {
            this.alertService.openModal(
                  'Confirmación',
                  '¿Está seguro de asignarse este crédito?',
                  'warning',
                  { acceptText: 'Aceptar', cancelText: 'Cancelar', showCancel: true }
            ).subscribe((result) => {
                  if (result) {
                        toggleLoader(this.loaderService, true);
                        this.creditRequestService.passToStudy(requestId).subscribe(
                              (response) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          '¡Asignación exitosa!',
                                          'El crédito ha sido asignado correctamente.',
                                          'success',
                                    ).subscribe(() => {
                                          this.creditRequestCardService.emitRefreshCards();
                                    });
                              },
                              (error) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          'Error',
                                          'Ocurrió un error al asignar el crédito.',
                                          'error'
                                    );
                              }
                        );
                  }
            });
      }

      /**
       * Mueve una solicitud de crédito a preaprobado.
       * @param {any} creditRequestStudy - Solicitud de crédito a mover.
       */
      moveToPreapproved(creditRequestStudy: any) {
            this.alertService.openModal(
                  'Confirmación',
                  this.getMessageInfoRequest(creditRequestStudy),
                  'warning',
                  { acceptText: 'Aceptar', cancelText: 'Rechazar', showCancel: true }
            ).subscribe((result) => {
                  if (result) {
                        toggleLoader(this.loaderService, true);
                        this.creditRequestService.passToPreaproved(creditRequestStudy.id).subscribe(
                              (response) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          '¡Preaprobación exitosa!',
                                          'La solicitud ha sido preaprobada.',
                                          'success'
                                    ).subscribe(() => {
                                          this.creditRequestCardService.emitRefreshCards();
                                    });
                              },
                              (error) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          'Error',
                                          'Ocurrió un error al preaprobar la solicitud.',
                                          'error'
                                    );
                              }
                        );
                  } else {
                        this.handleRejection(creditRequestStudy.id);
                  }
            });
      }

      /**
       * Envía el link del pagaré al cliente.
       * @param {CreditRequestInterface} creditRequest - Solicitud de crédito.
       */
      sendLinkPagare(creditRequest: CreditRequestInterface) {
            this.alertService.openModal(
                  'Confirmación',
                  '¿Está seguro de enviar el link de pagare al cliente?',
                  'warning',
                  { acceptText: 'Aceptar', cancelText: 'Cancelar', showCancel: true }
            ).subscribe((result) => {
                  if (result) {
                        toggleLoader(this.loaderService, true);
                        this.creditRequestService.sendPagareLink(creditRequest.id).subscribe({
                              next: (response) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          '¡Envío exitoso!',
                                          'El link de pagare ha sido enviado correctamente.',
                                          'success'
                                    );
                              },
                              error: (error) => {
                                    toggleLoader(this.loaderService, false);
                                    this.alertService.openModal(
                                          'Error',
                                          'Ocurrió un error al enviar el link de pagare.',
                                          'error'
                                    );
                              }
                        });
                  }
            });
      }

      /**
       * Verifica la firma del pagaré.
       * @param {CreditRequestInterface} creditRequestStudy - Solicitud de crédito a verificar.
       */
      verifyPromissoryNoteSignature(creditRequestStudy: CreditRequestInterface) {
            this.pasarToGestionados(creditRequestStudy);
            toggleLoader(this.loaderService, true, 'Verificando pagaré firmado')
            this.creditRequestService.verifyPromissoryNoteSignature(creditRequestStudy.customer_id).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false)
                  if (res.signed) {
                        this.pasarToGestionados(creditRequestStudy);
                  } else {
                        this.alertService.openModal(
                              'Error',
                              res.message + " Codigo de error => " + res.code_error,
                              'error'
                        );
                  }
            });
      }

      /**
       * Pasa una solicitud de crédito a gestionados.
       * @param {CreditRequestInterface} creditRequestStudy - Solicitud de crédito a gestionar.
       */
      pasarToGestionados(creditRequestStudy: CreditRequestInterface) {
            let valuesNota: any;

            this.alertService.openModal(
                  'Confirmación',
                  this.getMessageInfoRequest(creditRequestStudy),
                  'warning',
                  { acceptText: 'Aceptar', cancelText: 'Rechazar', showCancel: true }
            ).subscribe((result) => {
                  if (result) {
                        let modalRef;
                        if (creditRequestStudy.request_type === 'Registro') {
                              modalRef = this.modalService.open(PreaprovedNoteComponent, { centered: true });
                              modalRef.componentInstance.customerId = creditRequestStudy.customer_id;
                              modalRef.result.then((values) => {
                                    if (values) {
                                          valuesNota = values;
                                    }
                              })
                        } else {
                              modalRef = { result: Promise.resolve() };
                        }
                        modalRef.result.then(
                              (result) => {
                                    const modalRef = this.modalService.open(DynamicFormModalComponent, { centered: true });
                                    modalRef.componentInstance.title = 'Aprobar solicitud';
                                    modalRef.componentInstance.fields = [
                                          { type: 'number', label: 'Ingrese el monto a aprobar', key: 'montoAprobar', format: 'currency' }
                                    ];
                                    modalRef.componentInstance.validations = {
                                          montoAprobar: (value: any) => {
                                                if (creditRequestStudy.value_approve && Number(value) >= (creditRequestStudy.value_approve + 1)) {
                                                      return `El monto debe ser menor a ${'$' + creditRequestStudy.request_value.toLocaleString('en-US')} (Monto Preaprobado)`;
                                                }
                                                return null;
                                          }
                                    };
                                    modalRef.componentInstance.onConfirm.subscribe((data: any) => {
                                          toggleLoader(this.loaderService, true);
                                          const montoAprobar = Number(data.montoAprobar.replace(/[^0-9]+/g, ""));
                                          this.creditRequestService.passToApproved(creditRequestStudy.id, montoAprobar).subscribe(
                                                (response) => {
                                                      toggleLoader(this.loaderService, false);
                                                      this.alertService.openModal(
                                                            'Éxito',
                                                            'La solicitud ha sido aprobada.',
                                                            'success'
                                                      ).subscribe(() => {
                                                            this.creditRequestCardService.emitRefreshCards();
                                                      });
                                                      this.dashboardCollectionsService.createNote(valuesNota).subscribe(
                                                            (response) => {
                                                                  toggleLoader(this.loaderService, false);
                                                                  modalRef.close();
                                                            },
                                                            (error) => {
                                                                  toggleLoader(this.loaderService, false);
                                                            }
                                                      );
                                                },
                                                (error) => {
                                                      toggleLoader(this.loaderService, false);
                                                      this.alertService.openModal(
                                                            'Error',
                                                            'Ocurrió un error al aprobar la solicitud.',
                                                            'error'
                                                      );
                                                }
                                          );
                                    });
                              })
                  } else {
                        this.handleRejection(creditRequestStudy.id);
                  }
            });
      }

      /**
       * Devuelve una solicitud de crédito a estudio.
       * @param {number} creditRequestManaged - ID de la solicitud de crédito a devolver.
       */
      returnToStudy(creditRequestManaged: number) {
            const modalRef = this.modalService.open(DynamicFormModalComponent, { centered: true });
            modalRef.componentInstance.title = 'Devolución a estudio';
            modalRef.componentInstance.fields = [
                  { type: 'textarea', label: 'Ingrese el motivo de devolución', key: 'motivoDevolucion' }
            ];
            modalRef.componentInstance.validations = {
                  motivoDevolucion: (value: any) => {
                        if (!value) {
                              return 'El motivo de devolución es obligatorio';
                        }
                        return null;
                  }
            };
            modalRef.componentInstance.onConfirm.subscribe((data: any) => {
                  toggleLoader(this.loaderService, true);
                  const motivoDevolucion = data.motivoDevolucion;
                  this.creditRequestService.returnToStudy(creditRequestManaged, motivoDevolucion).subscribe(
                        (response) => {
                              toggleLoader(this.loaderService, false);
                              this.alertService.openModal(
                                    'Éxito',
                                    'La solicitud ha regresado a estudio.',
                                    'success'
                              ).subscribe(() => {
                                    this.creditRequestCardService.emitRefreshCards();
                              });
                        },
                        (error) => {
                              toggleLoader(this.loaderService, false);
                        }
                  );
            });
      }

      /**
       * Maneja el rechazo de una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito a rechazar.
       * @private
       */
      private handleRejection(creditRequestId: number) {
            const rejectionOptions = [
                  'Falta de documentación',
                  'Información incorrecta',
                  'No cumple con los requisitos',
                  'Otros motivos',
            ];

            const modalRef = this.modalService.open(DynamicFormModalComponent, { centered: true });
            modalRef.componentInstance.title = 'Rechazar solicitud';
            modalRef.componentInstance.fields = [
                  { type: 'select', label: '¿Por qué rechazas esta solicitud?', key: 'rejectionReason', options: rejectionOptions, placeholder: 'Seleccione un motivo' }
            ];
            modalRef.componentInstance.onConfirm.subscribe((data: any) => {
                  toggleLoader(this.loaderService, true);
                  const motivoRechazo = rejectionOptions[data.rejectionReason];
                  this.creditRequestService.passToRejected(creditRequestId, motivoRechazo).subscribe(
                        (response) => {
                              toggleLoader(this.loaderService, false);
                              this.alertService.openModal(
                                    'Éxito',
                                    'La solicitud ha sido rechazada.',
                                    'success'
                              ).subscribe(() => {
                                    this.creditRequestCardService.emitRefreshCards();
                              });
                        },
                        (error) => {
                              toggleLoader(this.loaderService, false);
                              this.alertService.openModal(
                                    'Error',
                                    'Ocurrió un error al rechazar la solicitud.',
                                    'error'
                              );
                        }
                  );
            });
      }

      /**
       * Obtiene el mensaje de información de la solicitud.
       * @param {CreditRequestInterface} request - Solicitud de crédito.
       * @returns {string} Mensaje formateado con la información de la solicitud.
       */
      getMessageInfoRequest(request: CreditRequestInterface): string {
            const message = `
      <b>CC:</b> ${request.customer.identification}<br>
      <b>Solicita:</b> ${'$' + request.request_value.toLocaleString('en-US')}<br>
      <b>Celular:</b> ${request.customer.customer_phones[0]?.phone_number}<br>
      <b>Proveedor:</b> ${request.shop_commerce.shop.social_reason}<br>
      <b>Sucursal:</b> ${request.shop_commerce.name}
    `;
            return message;
      }

      /**
       * Obtiene el estilo del encabezado según el tipo de solicitud.
       * @param {string} requestType - Tipo de solicitud.
       * @returns {Object} Objeto con el estilo del encabezado.
       */
      getHeaderStyle(requestType: string): { 'background-color': string } {
            const colorMap: { [key: string]: string } = {
                  'Aumento': '#bbffbf',
                  'Registro': '#abf2f5',
                  'Actualizacion': '#c2bbff'
            };
            return { 'background-color': colorMap[requestType] || '' };
      }

      /**
       * Obtiene la descripción del estado según su ID.
       * @param {number} stateId - ID del estado.
       * @returns {string} Descripción del estado.
       */
      getStateDescription(stateId: number): string {
            const stateDescriptions: { [key: number]: string } = {
                  0: 'Solicitud',
                  1: 'Solicitud',
                  2: 'Estudio',
                  3: 'Aprobado',
                  4: 'Rechazado',
                  5: 'Aprobado con desembolso',
                  6: 'Anulado',
                  7: 'Finalizado'
            };
            return stateDescriptions[stateId] || '';
      }

      confirmAutomaticApproval(creditRequestId: number, customerId: number, requestValue: number) {
            this.alertService.openModal(
                  'Confirmación de Aprobación Automática',
                  '¿Está seguro de pasar a aprobación automática?',
                  'warning',
                  { acceptText: 'Aceptar', cancelText: 'Cancelar', showCancel: true }
            ).subscribe((result) => {
                  if (result) {
                        const modalRef = this.modalService.open(DynamicFormModalComponent, { centered: true });
                        modalRef.componentInstance.title = 'Valor solicitado';
                        modalRef.componentInstance.fields = [
                              { 
                                    type: 'number', 
                                    label: 'Digite el valor solicitado por el cliente:', 
                                    key: 'valorSolicitado', 
                                    format: 'currency',
                                    value: requestValue
                              }
                        ];
                        modalRef.componentInstance.validations = {
                              valorSolicitado: (value: any) => {
                                    if (Number(value) <= 0) {
                                          return 'Debe ingresar un monto válido';
                                    }
                                    return null;
                              }
                        };
                        modalRef.componentInstance.onConfirm.subscribe((data: any) => {
                              toggleLoader(this.loaderService, true);
                              this.creditRequestService.automaticApproval({
                                    credit_request_id: creditRequestId,
                                    customer_id: customerId,
                                    value_request: data.valorSolicitado
                              }).subscribe({
                                    next: (response) => {
                                          toggleLoader(this.loaderService, false);
                                          this.alertService.openModal(
                                                'Éxito',
                                                response.message,
                                                'success'
                                          );
                                          this.creditRequestCardService.emitRefreshCards();
                                    },
                                    error: (error) => {
                                          toggleLoader(this.loaderService, false);
                                          this.alertService.openModal(
                                                'Error',
                                                error.error.message || error,
                                                'error'
                                          );
                                    }
                              });
                        })
                  }
            })
      }
}
