<div class="row my-4">
    <div class="col-12">
        <h5>DOCUMENTOS ASOCIADOS</h5>
    </div>
</div>
<div class="form-group justify-content-between d-flex">
    <input type="file" #fileInput class="form-control-file" />
    <button type="button" class="btn btn-primary" (click)="subirDocumento(fileInput)">
        Subir documento
    </button>
</div>
<div>
    <hr />
    <div>
        <h6>Documentos agregados:</h6>
        <ul class="files-content">
            @for (document of documentsList; track document; let i = $index) {
                <li class="ng-star-inserted">
                    <div class="d-flex align-items-center files-list">
                        <div class="flex-shrink-0 file-left">
                            <i class="fa-solid fa-file" [style]="{'scale': '2'}"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h6 class="mb-1">Documento # {{ i + 1 }}</h6>
                            <h6 class="mb-1">Nombre: {{ document.file_name }}</h6>
                            <button class="btn btn-primary" (click)="downloadFile(document.file)">
                                Descargar archivo
                            </button>
                            <p>Fecha creación : <small>{{ document.created | date:'yyyy MM dd' }}</small></p>
                        </div>
                    </div>
                </li>
            }
        </ul>
    </div>
</div>