<div class="w-100 px-2">
      <div class="card shadow-sm sticky-top bg-white" style="top: 66px; z-index: 500;">
            <div class="card-body p-2">
                  <div class="fs-5 d-flex justify-content-between align-items-center">
                        <span class="fw-bold">{{ title }}</span>
                        @if (paginator) {
                              <span class="badge bg-primary">
                                    {{ paginator.total }}
                              </span>
                        }
                  </div>
            </div>
      </div>
      <div class="fs-5 mt-2 px-2 d-flex justify-content-between">
            <span>Total:</span>
            <span>
                  {{ valueRequests | currency : '$' : 'symbol' : '1.0-0' }}
            </span>
      </div>
      @if (paginator) {
            <div class="d-flex justify-content-center">
                  <app-paginator [paginator]="paginator" [compact]="true"
                        (pageChangeEvent)="onPageChange($event)"></app-paginator>
            </div>
      }
      @for (request of requests; track request.id) {
            <app-credit-request-card [request]="request" [typeGroup]="type"></app-credit-request-card>
      }
</div>
