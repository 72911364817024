import { Routes } from '@angular/router';

export const CustomersRoutes: Routes = [
      {
            path: 'listado',
            loadComponent: () => import('./pages/customers-list-index/customers-list-index.component')
      },
      {
            path: 'historial/:id',
            loadComponent: () => import('./pages/customers-information-index/customers-information-index.component')
      },
      {
            path: 'pagos/:id',
            loadComponent: () => import('./pages/customers-outstanding-credits-index/customers-outstanding-credits-index.component')
      }
];
