import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { LoaderService } from '../../services/loader.service';


@Component({
      selector: 'app-loader',
      standalone: true,
      imports: [AsyncPipe],
      templateUrl: './loader.component.html',
      styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit {

      public message: string = 'Cargando...';

      constructor(public loaderService: LoaderService) { }

      ngOnInit(): void {
            this.loaderService.message$.subscribe(( message: string ) => {
                  if( message ) this.message = message;
            })
      }
}
