import { Routes } from '@angular/router';

export const CustomerRoutes: Routes = [
    {
        path: 'inicio',
        loadComponent: () => import('./pages/customer-index/customer-index.component').then(m => m.CustomerIndexComponent)
    },
    {
        path: "solicitudes-credito",
        loadComponent: () => import('./pages/customer-request-increase/customer-request-increase.component').then(m => m.CustomerRequestIncreaseComponent)
    },
    {
        path: "compras",
        loadComponent: () => import('./pages/customer-purchases/customer-purchases.component').then(m => m.CustomerPurchasesComponent)
    },
    {
        path: "pagos",
        loadComponent: () => import('./pages/customer-payments/customer-payments.component').then(m => m.CustomerPaymentsComponent)
    },
    {
        path: "compras-sin-entregar",
        loadComponent: () => import('./pages/customer-purchases-not-delivered/customer-purchases-not-delivered.component').then(m => m.CustomerPurchasesNotDeliveredComponent)
    },
];
