import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { CreditRequestGroupComponent } from '../../components/credits-requests-cards/credit-request-group/credit-request-group.component';
import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActionButtonsComponent } from '../../../../shared/components/action-buttons/action-buttons.component';
import { dataFilter, searchActions } from '../../../../shared/interfaces/action-buttons.interface';
import { CreditRequestCardService } from '../../services/credit-request-card.service';

@Component({
      selector: 'app-credits-requests-card',
      templateUrl: './credits-requests-card.component.html',
      standalone: true,
      imports: [
            CommonModule,
            BreadcrumbComponent,
            CreditRequestGroupComponent,
            ActionButtonsComponent
      ]
})
export class CreditsRequestsCardComponent {
      breadcrumbItems = [
            {
                  label: 'Solicitudes de crédito',
                  url: '/credits-requests',
            },
      ];
      dataFilter: dataFilter = {
            dataForm: [
                  { label: "Identificación", control: "identification", type: "text" },
                  { label: "Fecha de inicio", control: "startDate", type: "date" },
                  { label: "Fecha de fin", control: "endDate", type: "date" },
                  { label: "Hora de inicio", control: "startHouer", type: "time" },
                  { label: "Hora de fin", control: "endHouer", type: "time" },
            ],
            importsActions: [],
            buttonsSearch: [],
            otherActions: []
      };

      queryParams: any = { identification: '' };
      @ViewChildren(CreditRequestGroupComponent) creditRequestGroupComponents!: QueryList<CreditRequestGroupComponent>;

      constructor(private creditRequestCardService: CreditRequestCardService) {}

      /**
       * Inicializa el componente y se suscribe a los eventos de actualización de tarjetas.
       */
      ngOnInit(): void {
            this.creditRequestCardService.refreshCards$.subscribe(() => {
                  this.refreshData();
            });
      }

      /**
       * Maneja las acciones de búsqueda y actualización.
       * @param {searchActions} event - El evento de búsqueda o actualización.
       */
      handleSearchAction(event: searchActions): void {
            if (event.type === 'search') {
                  this.filterData(event.form);
            } else if (event.type === 'refresh') {
                  this.refreshData();
            }
      }

      /**
       * Filtra los datos en todos los componentes de grupo de solicitudes de crédito.
       * @param {any} form - El formulario con los criterios de filtrado.
       */
      filterData(form: any) {
            this.creditRequestGroupComponents.forEach(component => component.filterData(form));
      }

      /**
       * Actualiza los datos en todos los componentes de grupo de solicitudes de crédito.
       */
      refreshData() {
            this.creditRequestGroupComponents.forEach(component => component.refreshData());
      }
}