@if (isAdmin) {
  <h2>Score: {{ customerInfo.score }}</h2>
}
<div class="row my-4">
    <div class="col-12">
        <h5>Solicitud diligenciada por el cliente</h5>
    </div>
</div>
<form [formGroup]="customerEditForm">
    <div class="row">
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>ID Usuario</label>
                <input formControlName="id" type="text" class="form-control" [disabled]="true" />
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Nit</label>
                <input formControlName="nit" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Nombre de la empresa</label>
                <input formControlName="buss_name" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Departamento</label>
                <select (change)="changeArrayCities()" formControlName="department_id" class="form-select">
                    @for (department of departments; track department.id) {
                        <option [value]="department.id">{{ department.name }}</option>
                    }
                </select>
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Ciudad</label>
                <select formControlName="city_id" class="form-select">
                    @for (city of cities; track city.id) {
                        <option [value]="city.id">{{ city.name }}</option>
                    }
                </select>
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Dirección de la Empresa</label>
                <input formControlName="businness_address" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Registrado en Cámara de Comercio</label>
                <select formControlName="registered_chamber_commerce" class="form-select">
                    @for (cci of ccis; track cci.id) {
                        <option [value]="cci.id">{{cci.name }}</option>
                    }
                    <option [value]="null"> Seleccione una opción </option>
                </select>
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>Nombres</label>
                <input formControlName="name" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Apellidos</label>
                <input formControlName="last_name" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>Tipo de identificación</label>
                <select formControlName="identification_type" class="form-select">
                    @for (typeIdentification of typeIdentifications; track typeIdentification.id) {
                        <option [value]="typeIdentification.id">{{typeIdentification.name }}</option>
                    }
                </select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Número de identificación</label>
                <input formControlName="identification" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Número de Celular</label>
                <input formControlName="phone" type="text" class="form-control" />
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Número de Celular 2</label>
                <input formControlName="phone_2" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Email</label>
                <input formControlName="email" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Dirección</label>
                <input formControlName="address" type="text" class="form-control" />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Fecha de nacimiento</label>
                <input
                  formControlName="date_of_birth"
                  type="date"
                  class="form-control"
                  [disabled]="datosDeshabilitados"
                />
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mt-2">
                <label>Fecha de expedición</label>
                <input
                  formControlName="emission_date"
                  type="date"
                  class="form-control"
                  [disabled]="datosDeshabilitados"
                />
            </div>
        </div>
    </div>
    <div class="row justify-content-end mt-3">
        <div class="col-12 text-end">
            @if (canSendWhatsapp) {
                <button type="button" class="btn btn-success me-2" (click)="sendWhatsappLinkDeceval()">
                    Enviar enlace de pagare
                </button>
            }
            <button type="button" class="btn btn-primary me-2" (click)="toggleEnableForm()">
                <i class="fas fa-save ml-2"></i> Editar
            </button>
            <button type="button" class="btn btn-success" (click)="update()">
                <i class="fas fa-save ml-2"></i> Actualizar
            </button>
        </div>
    </div>
</form>

<hr />
<div class="row my-2">
    <div class="col-12">
        <h5>FOTOS CLIENTE</h5>
    </div>
</div>
<div>
    <a [href]="customerInfo.document_file_up" target="_blank" class="btn btn-danger me-2">
        <i class="fas fa-camera"></i>
        Ver foto frontal
    </a>
    <a [href]="customerInfo.document_file_down" target="_blank" class="btn btn-danger me-2">
        <i class="fas fa-camera"></i>
        Ver foto reverso
    </a>
    <a [href]="customerInfo.image_file" target="_blank" class="btn btn-danger">
        <i class="fas fa-camera"></i>
        Ver selfie
    </a>
</div>

<!-- Extraccion de la informacìon de la IA -->

<div class="row" *ngIf="getIADescription('descripcion_ia_document_file_up') as description">
  <div class="col-12">
    <hr />
  </div>
  <app-ia-extraction [description]="description"></app-ia-extraction>
</div>

<hr>

<div class="row my-2">
    <div class="col-12">
        <h5>NOTAS</h5>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="form-group">
            <textarea class="form-control" [(ngModel)]="textareaContent"></textarea>
        </div>
    </div>
</div>
<div class="row justify-content-end">
    <div class="col-md-2 mt-3 text-end">
        <button type="button" class="btn btn-success" (click)="saveNotes()">
            <i class="fas fa-save ml-2"></i> Guardar
        </button>
    </div>
</div>
<hr />
<div class="card">
    <div class="card-header pb-0">
        <div class="media">
            <div class="media-body">
                <h5 class="mb-0">Notas agregadas:</h5>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="activity-media">
            @for (nota of notasAgregadas; track nota) {
                <div class="media">
                    <div class="recent-circle bg-primary"></div>
                    <div class="media-body">
                        <h6 class="font-roboto mb-0">{{ nota.note }}</h6>
                        <span>
                            <i class="fas fa-clock"></i>
                            <span class="font-roboto">
                                Nota dejada el {{ nota.created }} por {{ nota.user }}
                            </span>
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>
</div>