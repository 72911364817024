import { Routes } from '@angular/router';

export const CustomerServicesParametersRoutes: Routes = [
      {
            path: '',
            loadComponent: () => import('./general/pages/csp-index/csp-index.component')
      },
      {
            path: 'contacto',
            loadComponent: () => import('./general/pages/csp-contact-index/csp-contact-index.component')
      },
      {
            path: 'contacto/directo',
            loadComponent: () => import('./general/pages/csp-contact-direct-index/csp-contact-direct-index.component')
      },
      {
            path: 'contacto/indirecto',
            loadComponent: () => import('./general/pages/csp-contact-indirect-index/csp-contact-indirect-index.component')
      },
      {
            path: 'contacto/no',
            loadComponent: () => import('./general/pages/csp-contact-not-index/csp-contact-not-index.component')
      },
      {
            path: 'motivos-mora',
            loadComponent: () => import('./general/pages/csp-motives-mora-index/csp-motives-mora-index.component')
      },
      {
            path: 'atajos-gestion',
            loadComponent: () => import('./general/pages/csp-shortcuts-management-index/csp-shortcuts-management-index.component')
      },
      {
            path: 'etiquetas-sistema',
            loadComponent: () => import('./general/pages/csp-tags-system-index/csp-tags-system-index.component')
      },
      {
            path: 'segmentos',
            loadComponent: () => import('./general/pages/csp-segments-index/csp-segments-index.component')
      },
      {
            path: 'reason-consultation',
            loadComponent: () => import('./general/pages/csp-reason-consultation-index/csp-reason-consultation-index.component')
      },
      {
            path: 'shortcuts-whatsapp',
            loadComponent: () => import('./general/pages/csp-shortcuts-main-index/csp-shortcuts-main-index.component')
      },
      {
            path: 'plantillas-whatsapp',
            loadComponent: () => import('./template-whatsapp/pages/cspt-whatsapp-index/cspt-whatsapp-index.component')
      },
      {
            path: 'plantillas-email',
            loadComponent: () => import('./template-email/pages/template-email-index/template-email-index.component')
      }
];
