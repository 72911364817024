<form [formGroup]="formNote" class="p-4">
      <div class="row">
            <h3>Aprobar cliente</h3>
      </div>

      <angular-editor class="rounded-1" [config]="config" formControlName="note"></angular-editor>

      <div class="row mt-3">
            <div class="col-6 form-group">
                  <label>Hora [hh:mm]</label>
                  <input class="form-control" formControlName="hour" type="text" appTimeFormat />
            </div>
            <div class="col-6 form-group">
                  <label>Duración [hh:mm]</label>
                  <input class="form-control" formControlName="duration" type="text" appTimeFormat />
            </div>
      </div>

      <div class="col-12">
            <div class="justify-content-end d-flex py-3">
                  <button class="btn btn-primary btn-sm" (click)="saveNote()">
                        <i class="fas fa-share-square"></i>
                        Aprobar
                  </button>
            </div>
      </div>
</form>