import { Routes } from '@angular/router';
import { CreditsRequestsCardComponent } from './pages/credits-requests-cards/credits-requests-card.component';
import { CreditsRequestsListComponent } from './pages/credits-requests-list/credits-requests-list.component';

export const CreditsRequestsRoutes: Routes = [
      {
            path: 'listado',
            component: CreditsRequestsListComponent,
      },
      {
            path: 'tarjeta',
            component: CreditsRequestsCardComponent,
      },
];
