import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { CommerceDashboardService, MetricType } from '../../services/commerce-dashboard.service';
import { CommerceDashboardResponseInterface, DataMiniCards, SalesByCityInterface, SalesCity, TotalDayInterface, TotalMonthInterface, TotalShopQuotaInterface } from '../../interfaces/commerce-dashboard.interface';
import { LoaderService } from '../../../../shared/services/loader.service';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { CommonModule } from '@angular/common';
import { MiniCardsDashboardComponent } from '../../components/mini-cards-dashboard/mini-cards-dashboard.component';
import { CouponDashboardComponent } from '../../components/coupon-dashboard/coupon-dashboard.component';
import { SalesDashboardComponent } from '../../components/sales-dashboard/sales-dashboard.component';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { finalize } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { SpinnerGraphicComponent } from '../../../../shared/components/spinner-graphic/spinner-graphic.component';
import { GraphicDashboardComponent } from '../../components/graphic-dashboard/graphic-dashboard.component';


@Component({
  selector: 'app-home-dashboard',
  standalone: true,
  imports: [CommonModule, SalesDashboardComponent, CouponDashboardComponent, MiniCardsDashboardComponent, BreadcrumbComponent, SpinnerGraphicComponent, GraphicDashboardComponent],
  templateUrl: './home-dashboard.component.html',
  styleUrl: './home-dashboard.component.scss'
})
export class HomeDashboardComponent {
  /** Tipo de métrica protegido */
  protected MetricType = MetricType;

  /** Datos para las mini tarjetas del dashboard */
  public dataOfminiCards!: DataMiniCards

  /** Servicio del dashboard de comercio */
  private commerceDashboardService = inject(CommerceDashboardService)

  /** Referencia para destruir subscripciones */
  private destroyRef = inject(DestroyRef)

  /** Servicio del loader */
  private loaderService = inject(LoaderService)

  /** Servicio de modales de alerta */
  private modalService = inject(ModalServiceAlert)

  /** Detector de cambios */
  private cdr = inject(ChangeDetectorRef)

  /** Total del día */
  public totalDay!: TotalDayInterface

  /** Total del mes */
  public totalMonth!: TotalMonthInterface

  /** Datos del cupón */
  public dataOfCoupon!: TotalShopQuotaInterface

  /** Fecha actual */
  public date!: string

  /** Ciudades con ventas */
  public salesCity!: SalesByCityInterface[];
  
  /** Página actual de la paginación de ciudades */
  public currentPageCity: number = 1;

  /** Estado de la gráfica */
  public stateGraphic!: boolean

  /** Límite de ciudades por página */
  public limitCity!: number

  /** Datos a enviar para la gráfica de ciudades */
  public dataSendCity!: SalesCity;

  /** 
   * Inicializa el componente obteniendo los datos del dashboard
   */
  ngOnInit(): void {
    this.getCommerceDashboardData()
  }

  /** Items del breadcrumb */
  breadcrumbItems = [
    { label: "", url: "" },
  ];

  /**
   * Obtiene los datos del dashboard de comercio
   * Incluye datos de mini tarjetas, totales por día y mes,
   * datos de cupones y ventas por ciudad
   */
  public getCommerceDashboardData(){
    toggleLoader(this.loaderService, true, 'Obteniendo datos...')
    this.commerceDashboardService.getDataDashboard().pipe(
      finalize(() => toggleLoader(this.loaderService, false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe({
      next: (response) => {
        console.log(response)
        this.dataOfminiCards = {
          totalCustomers: response.data.total_customers,
          withdrawnCredits: response.data.withdrawn_credits,
          withdrawnCreditsCount: response.data.withdrawn_credits_count,
          creditsRequestsCount: response.data.credits_requests_count
        }
        this.getTotalSales(response)
        

      },
      error: (err) => {
        console.log(err)
        this.modalService.openModal('Error', err.error.message || 'Error al obtener los datos', 'error')
      }
    })
  }

  /**
   * Maneja la paginación de las ciudades y actualiza los datos de la gráfica
   * @param {number} index - Número de página a mostrar
   */
   public changeCitysBypagination( index: number ){
    
    this.stateGraphic = false; 
    this.currentPageCity = index; 
    this.limitCity = Math.ceil(this.salesCity.length / 15) 
    
    const dataChoosed = this.salesCity.slice((15 * ( this.currentPageCity - 1 )),( (15 * this.currentPageCity)  ))

    let label: string[] = []
    let data: number[] = [] 
    let id: number[] = []

    dataChoosed.forEach( ( city ) => {
          label.push( city.city )
          data.push( city.total_sales )
          id.push( city.id )
    })  

    this.dataSendCity = {
      label: label,
      data: data,
      id: id
    }
    
    setTimeout(() =>{
      this.stateGraphic = true;
      this.cdr.detectChanges()
    }, 1500)
  }


  public getTotalSales( response: CommerceDashboardResponseInterface ){
    this.totalDay = response.data.total_day
    this.totalMonth = response.data.total_month
        this.dataOfCoupon = response.data.total_shop_quota
        this.date = response.data.total_month.current.date
        this.salesCity = response.data.sales_by_city
        this.changeCitysBypagination(1)
  }


  



}
