<div class="header-wrapper d-flex align-items-center justify-content-between">
    <div class="header-logo-wrapper col-auto p-0 d-flex gap-2 align-items-center">
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <div class="status_toggle sidebar-toggle d-flex">
          <i class="fa-solid fa-bars" style="color: #064853; font-size: 30px; padding-right: 10px;"></i>
        </div>
      </div>
  
      <div class="left-side-header col ps-0 d-md-block">
        <!-- 5 y 10 son los roles donde no le va salir  el buscar cliente, ya que son roles de cliente y  basico -->
        @if (![5, 10].includes(role)) {
            <div >
                <app-search></app-search>
            </div>
        }
      </div>
    </div>
    
  
    <div class="user">
      <div class="profile-nav onhover-dropdown pe-0 py-0 me-0">
        <app-account></app-account>
      </div>
    </div>
  
  </div>
  