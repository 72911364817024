import { Component } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { AuthService } from '../../../auth/login/services/auth.service';
import { AccountComponent } from './account/account.component';
import { SearchComponent } from './search/search.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ AccountComponent, SearchComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

/** Almacena el rol del usuario actualmente autenticado. */
public role!: number;

/** Controla si la barra lateral está colapsada o no. */
public collapseSidebar: boolean = true;

/**
 * @param navServices Servicio que gestiona el estado de la barra lateral.
 * @param layout Servicio que maneja la configuración de diseño de la aplicación.
 * @param authService Servicio que gestiona la autenticación y los datos del usuario.
 */
constructor(
  private navServices: NavService,
  private authService: AuthService
) {}

/**
 * Método de ciclo de vida que se ejecuta al inicializar el componente.
 * Inicializa el tema y obtiene el rol del usuario.
 */
ngOnInit(): void {
  this.setUserRole();
}


/**
 * Establece el rol del usuario autenticado obteniendo los datos desde el AuthService.
 * Si no se puede obtener el rol, asigna 0 como valor predeterminado.
 * @private
 */
private setUserRole(): void {
  this.role = this.authService.getUser()?.role_id ?? 0;
}

/**
 * Alterna el estado de colapso de la barra lateral.
 * Cambia el valor de `collapseSidebar` en el NavService.
 */
public sidebarToggle(): void {
  this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
}


/**
 * Activa la búsqueda mostrando el cuadro de búsqueda en la interfaz.
 * Cambia el valor de `search` en el NavService a `true`.
 */
public toggleSearch(): void {
  this.navServices.search = true;
}
}
                                      