import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavService } from '../../../services/nav.service';
import { LayoutService } from '../../../services/layout.service';
import { NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { FooterComponent } from '../../footer/footer.component';
import { HeaderComponent } from '../../header/header.component';


@Component({
  selector: 'app-content',
  standalone: true,
  imports: [SidebarComponent, CommonModule, RouterModule, FooterComponent, HeaderComponent],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  animations: [
      trigger('routeAnimations', [
        transition('* <=> *', [
          style({ opacity: 0 }),
         animate('300ms', style({ opacity: 1 }))
        ])
      ])
    ]
})
export class ContentComponent {

    /**
     * Indica si el footer es oscuro.
     * @type {boolean}
     */
    footerDark: boolean = false;

    /**
     * Indica si el footer es claro.
     * @type {boolean}
     */
    footerLight: boolean = false;

    /**
     * Indica si el footer está fijo.
     * @type {boolean}
     */
    footerFix: boolean = false;

    /**
     * Indica si el footer está scrolleado.
     * @type {boolean}
     */
    Scorlled: boolean = false;

    /**
     * Indica si el chat de WhatsApp está activo.
     * @type {boolean}
     */
    isChatWhatsapp: boolean = false;

    /**
     * Suscripción a los eventos de la ruta.
     * @type {Subscription}
     * @private
     */
    private routeSubscription!: Subscription;

    /**
     * Indica si se debe mostrar el contenido.
     * @type {boolean}
     */
    public show: boolean = true;

    /**
     * Ancho de la pantalla.
     * @type {number}
     */
    public screenwidth: number = window.innerWidth;

    /**
     * Constructor del componente.
     * @param {NavService} navServices - Servicio de navegación.
     * @param {LayoutService} layout - Servicio de layout.
     * @param {Router} router - Servicio de enrutamiento.
     */
    constructor(
        public navServices: NavService,
        public layout: LayoutService,
        private router: Router
    ) {}

    /**
     * Maneja el evento de redimensionamiento de la ventana.
     * @param {Event} event - Evento de redimensionamiento.
     */
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenwidth = event.target.innerWidth;
        this.layout.config.settings.sidebar =
            this.screenwidth < 991 ? 'compact-wrapper' : 'horizontal-wrapper';
    }

    /**
     * Devuelve el estado del router outlet para las animaciones.
     * @param {any} outlet - Router outlet.
     * @returns {any} Estado del router outlet.
     */
    public getRouterOutletState(outlet: any) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }

    /**
     * Inicializa el componente y suscribe a los eventos de navegación.
     */
    ngOnInit(): void {
        this.routeSubscription = this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.checkRoute(event.urlAfterRedirects);
            }
        });

        this.checkRoute(this.router.url);
    }

    /**
     * Verifica si la ruta contiene '/servicio-cliente/whatsapp'.
     * @param {string} url - URL a verificar.
     */
    checkRoute(url: string): void {
        this.isChatWhatsapp = url.includes('/servicio-cliente/whatsapp');
    }

    /**
     * Desuscribe de los eventos cuando el componente se destruye.
     */
    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    /**
     * Optimiza el chequeo de rutas.
     */
    ngDoCheck() {
        this.updateFooterState(window.location.pathname);
    }

    /**
     * Actualiza el estado del footer basado en la ruta actual.
     * @param {string} pathname - Ruta actual.
     * @private
     */
    private updateFooterState(pathname: string): void {
        const footerStates: { [key: string]: { footerDark: boolean; footerLight: boolean; footerFix: boolean; Scorlled: boolean } } = {
            '/page-layout/footer-dark': { footerDark: true, footerLight: false, footerFix: false, Scorlled: false },
            '/page-layout/footer-light': { footerDark: false, footerLight: true, footerFix: false, Scorlled: false },
            '/page-layout/footer-fixed': { footerDark: false, footerLight: false, footerFix: true, Scorlled: false },
            '/page-layout/hide-nav-scroll': { footerDark: false, footerLight: false, footerFix: false, Scorlled: true },
        };

        const state = footerStates[pathname] || { footerDark: false, footerLight: false, footerFix: false, Scorlled: false };
        this.footerDark = state.footerDark;
        this.footerLight = state.footerLight;
        this.footerFix = state.footerFix;
        this.Scorlled = state.Scorlled;
    }

    /**
     * Maneja el evento de scroll.
     */
    @HostListener('window:scroll', [])
    scrollHandler() {
        if (window.location.pathname === '/page-layout/hide-nav-scroll') {
            const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            this.show = number <= 600;
        }
    }

    /**
     * Prepara la animación de la ruta.
     * @param {RouterOutlet} outlet - Router outlet.
     * @returns {any} Animación de la ruta.
     */
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }


}
