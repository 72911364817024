@if ( loaderService.isLoading$ | async ) {

    <div class="loader-wrapper">
          <div class="loader">
                <img width="300" src="../../../../assets/images/logo/logo.gif" alt="Loader" />
                <div class="text-center text-white px-2 ">
                      {{ message }}
                </div>
          </div>
    </div> 
}                                                                                           
    