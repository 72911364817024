<div *ngIf="dataCoupon" class="card-grafic-content">
    <div *ngFor="let card of couponCards" class="card-grafic shadow-sm">
      <div class="ball d-flex justify-content-center align-items-center" [ngClass]="card.colorClass">
        <i class="fa-solid fa-money-bill" style="color: #ffffff;"></i>
      </div>
      <div class="mt-2">
        <h5>
          {{ card.title }} <br>
          <span class="price-span">
            {{ converterNumber(dataCoupon[card.dataKey]) | currency : "$" : "symbol" : "1.0-0" }}
          </span>
        </h5>
      </div>
    </div>
  </div>