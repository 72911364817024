import { Component, ViewChild } from '@angular/core';
import { CustomersSearchComponent } from '../../modals/customers-search/customers-search.component';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CustomersSearchComponent],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {

@ViewChild(CustomersSearchComponent) customersSearchComponent!: CustomersSearchComponent;


public openModalCustomerSearch() {
    this.customersSearchComponent.openModalCustomerSearch();
}

}
