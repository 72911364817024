import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../../../../../shared/services/loader.service';

@Component({
      selector: 'app-preaproved-note',
      standalone: true,
      imports: [CommonModule, AngularEditorModule, ReactiveFormsModule],
      templateUrl: './preaproved-note.component.html'
})
export class PreaprovedNoteComponent {
      @Input() customerId!: number;

      config: AngularEditorConfig = {
            editable: true,
            spellcheck: true,
            height: '10rem',
            minHeight: '5rem',
            placeholder: 'Crea el cuerpo del mensaje (Obligatorio)...',
            translate: 'si',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
            toolbarHiddenButtons: [['insertImage', 'insertVideo']],
            uploadWithCredentials: false,
            customClasses: [
                  { name: 'quote', class: 'quote' },
                  { name: 'redText', class: 'redText' },
                  { name: 'titleText', class: 'titleText', tag: 'h1' },
            ],
      };

      formNote: FormGroup;

      /**
       * Constructor del componente PreaprovedNoteComponent.
       * @param {FormBuilder} formBuilder - Servicio para construir formularios reactivos.
       * @param {LoaderService} loaderService - Servicio para manejar el estado de carga.
       * @param {NgbActiveModal} activeModal - Referencia al modal activo.
       */
      constructor(
            private formBuilder: FormBuilder,
            private loaderService: LoaderService,
            public activeModal: NgbActiveModal
      ) {
            this.formNote = this.formBuilder.group({
                  note: ['', Validators.required],
                  hour: [''],
                  duration: [''],
            });
      }

      /**
       * Guarda la nota pre-aprobada y cierra el modal.
       * Si el formulario es válido, prepara los valores y los envía al componente padre.
       */
      saveNote() {
            if (this.formNote.valid) {
                  const values = {
                        ...this.formNote.value,
                        is_whatsapp: false,
                        customer_id: this.customerId,
                        collection_type_contact_id: 10,
                        tags: 4,
                        text: this.formNote.get('note')?.value || 'LLamada onboarding efectiva',
                        type: 'Llamada',
                        typeContactType: 'Contacto Directo'
                  };

                  this.activeModal.close(values);
            }
      }
}