import { Component, input, InputSignal } from '@angular/core';
import { CustomerHistorialInterface, TotalCreditInterface } from '../../../../../interfaces';

@Component({
  selector: 'app-customers-data',
  standalone: true,
  imports: [],
  templateUrl: './customers-data.component.html',
  styleUrl: './customers-data.component.scss'
})
export class CustomersDataComponent {

  /**
   * @property {InputSignal<CustomerHistorialInterface>} customer
   * @description Señal de entrada requerida que contiene la información del cliente.
   */
  customer: InputSignal<CustomerHistorialInterface> = input.required<CustomerHistorialInterface>();

  /**
   * @property {InputSignal<TotalCreditInterface>} totals
   * @description Señal de entrada requerida que contiene los totales de crédito del cliente.
   */
  totals: InputSignal<TotalCreditInterface> = input.required<TotalCreditInterface>();


}
