import { Routes } from '@angular/router';
import { CustomerRoutes } from './customer/customer.routes';

import { CreditsRoutes } from './administrative/credits/credits.routes';
import { CustomersRoutes } from './administrative/customers/customers.routes';
import { CustomersCodeOtpRoutes } from './administrative/customers-code-otp/customers-code-otp.routes';
import { ManagementDefaultRateRoutes } from './administrative/management-default-rate/management-default-rate.routes';
import { CreditsRequestsRoutes } from './administrative/credits-requests/credits-requests.routes';
// import { CustomerServicesRoutes } from './administrative/customer-services/customer-services.routes';
import { ProvidersRoutes } from './administrative/providers/providers.routes';
import { UsersRoutes } from './administrative/users/users.routes';
// import { ParametersRoutes } from './administrative/parameters/parameters.routes';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { CommercesRoutes } from './administrative/commerces/commerces.routes';
import { CommerceRoutes } from './commerce/commerce.routes';
import { CustomerServicesParametersRoutes } from './administrative/customer-services/parameters/customer-services-parameters.routes';
import { CustomerServicesRoutes } from './administrative/customer-services/customer-services.routes';
import { ParametersRoutes } from './administrative/parameters/parameters.routes';
import { DashboardCollectionsRoutes } from './administrative/dashboard-collections/dashboard-colletions.routes';
import { SiigoRoutes } from './administrative/siigo/customer-services.routes';
import { PaymentsCustomerRoutes } from './administrative/payments-customer/payments-customer.routes';
import { AdministrativeDashboardRoutes } from './administrative/administrative-dashboards/administrative-dashboards.routes';
import { AuditsRoutes } from './administrative/audits/audits.routes';
import { PaymentsAlliesRoutes } from './administrative/payments-allies/payments-allies.routes';


export const routes: Routes = [
      /*** EMPTY HOME ***/
      {     //EMPTY HOME
            path: '',
            component: ContentComponent,
      },

      /*** LOGIN ***/
      {     //login
            path: 'iniciar-sesion',
            loadComponent: () => import('./auth/login/login.component')
      },

      /*** PAGO DE CLIENTES ***/
      {
            path: 'general/fastpayment',
            loadComponent: () => import('./payment-general/pages/payment-general-index/payment-general-index.component')
      },
      {
            path: 'tu-credito',
            loadComponent: () => import('./auth/register/register.component')
      },
      {
            path: 'iniciar-sesion',
            loadComponent: () => import('./auth/login/login.component')
      },
      {
            path: 'link-pago-clientes',
            loadComponent: () => import('./payment-general/pages/payment-general-index/payment-general-index.component')
      },

      /*** Cobranza ***/
      {
            path: 'cobranza',
            component: ContentComponent,
            children: DashboardCollectionsRoutes
      },

      /*** Clientes ***/
      {
            path: 'clientes',
            component: ContentComponent,
            children: CustomersRoutes
      },

      /*** Creditos ***/
      {
            path: 'creditos',
            component: ContentComponent,
            children: CreditsRoutes
      },

      /*** Codigos OTP ***/
      {
            path: 'codigos-otp',
            component: ContentComponent,
            children: CustomersCodeOtpRoutes
      },

      /*** Gestion de tasa de moras ***/
      {
            path: 'gestion-tasa-mora',
            component: ContentComponent,
            children: ManagementDefaultRateRoutes
      },

      /*** Servicio al cliente ***/
      {
            path: 'servicio-cliente',
            component: ContentComponent,
            children: CustomerServicesRoutes
      },

      /*** Parametros ***/
      {
            path: 'parametros',
            component: ContentComponent,
            children: ParametersRoutes
      },

      /*** Proveedores ***/
      {
            path: 'proveedores',
            component: ContentComponent,
            children: ProvidersRoutes
      },

      /*** Solicitudes ***/
      {
            path: 'solicitudes',
            component: ContentComponent,
            children: CreditsRequestsRoutes
      },

      /*** Sucursales ***/
      {
            path: 'sucursales',
            component: ContentComponent,
            children: CommercesRoutes
      },

      /*** Usuarios ***/
      {
            path: 'usuarios',
            component: ContentComponent,
            children: UsersRoutes
      },

      /*** Siigo ***/
      {
            path: 'siigo',
            component: ContentComponent,
            children: SiigoRoutes
      },

      /*** Pagos clientes ***/
      {
            path: 'pagos',
            component: ContentComponent,
            children: PaymentsCustomerRoutes
      },

      /*** Auditorias ***/
      {
            path: 'auditorias',
            component: ContentComponent,
            children: AuditsRoutes
      },

      /*** Pagos proveedores ***/
      {
            path: 'pagos',
            component: ContentComponent,
            children: PaymentsAlliesRoutes
      },

      /******Dashboards ******** */
      {
            path: 'panel',
            component: ContentComponent,
            children: AdministrativeDashboardRoutes
      },

      /* Rol cliente */
      {
            path: 'clientes',
            component: ContentComponent,
            children: CustomerRoutes
      },

      /* Rol proveedor */
      {
            path: 'webhook',
            component: ContentComponent,
            children: CommerceRoutes
      },

      /*** REDIRECT ***/
      {
            path: '',
            redirectTo: 'panel/inicio',
            pathMatch: 'full'
      },
];
