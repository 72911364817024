import { Component } from '@angular/core';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { ManagementDefaultRateRequestsService } from '../../services/management-default-rate.service';
import { LoaderService } from '../../../../shared/services/loader.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { LoaderWaitComponent } from '../../../../shared/components/modals/loader-wait/loader-wait.component';

@Component({
      selector: 'app-management-default-rate-list',
      templateUrl: './management-default-rate-list.component.html',
      standalone: true,
      imports: [
            CommonModule,
            FormsModule,
            LoaderWaitComponent
      ],
})
export class ManagementDefaultRateListComponent {
      months: string[] = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
      ];

      monthData: {
            month: string;
            current_rate: number;
            default_rate: number;
            date: string;
      }[] = [];

      modifyInputs: boolean = true;

      constructor(
            private managementDefaultRateService: ManagementDefaultRateRequestsService,
            private loaderService: LoaderService,
            private modalService: ModalServiceAlert
      ) { }

      /**
       * Inicializa el componente y obtiene la lista de tasas de mora.
       */
      ngOnInit() {
            toggleLoader(this.loaderService, true);
            this.getDefaultRateList();
      }

      /**
       * Obtiene el nombre del mes a partir de una fecha en formato string.
       * @param {string} dateString - La fecha en formato string.
       * @returns {string} El nombre del mes correspondiente a la fecha.
       */
      getMonthFromDate(dateString: string): string {
            const date = new Date(dateString + 'T00:00:00Z');
            const monthIndex = date.getUTCMonth();
            return this.months[monthIndex];
      }

      /**
       * Obtiene la lista de tasas de mora desde el servicio y la procesa.
       */
      getDefaultRateList() {
            this.managementDefaultRateService.getListManagementRate().subscribe(
                  (dataFromService) => {
                        this.monthData = this.months.map((month) => {
                              const matchingEntry = dataFromService.data.find(
                                    (d: any) => this.getMonthFromDate(d.date) === month
                              );
                              const formattedDate =
                                    new Date().getFullYear() +
                                    '-' +
                                    ('0' + (this.months.indexOf(month) + 1)).slice(-2);
                              return {
                                    month,
                                    current_rate:
                                          matchingEntry !== undefined ? matchingEntry.current_rate : 0,
                                    default_rate:
                                          matchingEntry !== undefined ? matchingEntry.default_rate : 0,
                                    date: formattedDate,
                              };
                        });

                        toggleLoader(this.loaderService, false);
                  }
            );
      }

      /**
       * Verifica si hay datos presentes para un mes específico.
       * @param {string} month - El mes a verificar.
       * @returns {boolean} True si hay datos presentes, false en caso contrario.
       */
      isDataPresent(month: string): boolean {
            const monthEntry = this.monthData.find((d) => d.month === month);
            this.modifyInputs = false;
            return (
                  !!monthEntry &&
                  (monthEntry.current_rate !== 0 || monthEntry.default_rate !== 0)
            );
      }

      /**
       * Guarda los datos de las tasas de mora en el servidor.
       */
      saveData(): void {
            toggleLoader(this.loaderService, true);

            this.managementDefaultRateService.setSaveRate(this.monthData).subscribe(
                  (dataFromService) => {
                        console.log(dataFromService);

                        toggleLoader(this.loaderService, false);

                        this.modalService.openModal(
                              'Success',
                              'Se actualizó las tasas de mora correctamente!',
                              'success'
                        );
                  }
            );
      }
}
