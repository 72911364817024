<div class="row">
    <div class="col-12">
      <h5 class="fw-bold">Extracción de información IA</h5>
      <p
        class="p-2 fw-bold"
        [ngClass]="{
          'bg-bajo-match': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) < 33,
          'bg-medio-match': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) >= 33 && 
                           getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) < 66,
          'bg-alto-match': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) >= 66
        }"
      >
        Coincidencia documento:
        <span
          [ngClass]="{
            'text-danger': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) < 33,
            'text-warning': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) >= 33 && 
                           getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) < 66,
            'text-success': getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) >= 66
          }"
        >
          {{
            description.resultado_comparacion?.confianza
              ? (getConfidencePercentage(description.resultado_comparacion?.confianza ?? null) | number:'1.0-0') + '%'
              : 'No disponible'
          }}
        </span>
      </p>
    </div>
  
    <div class="col-md-4">
      <p class="fw-bold">Foto frontal</p>
      <div class="mb-2">
        <strong>Descripción:</strong>
        {{ description.descripcion || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Sexo:</strong>
        {{ description.sexo || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Estatura:</strong>
        {{ description.estatura || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Nacionalidad:</strong>
        {{ description.nacionalidad || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Grupo Sanguíneo:</strong>
        {{ description.grupo_sanguineo || 'No disponible' }}
      </div>
    </div>
  
    <div class="col-md-4">
      <p class="fw-bold">Foto reverso</p>
      <div class="mb-2">
        <strong>Descripción:</strong>
        {{ description.descripcion || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Sexo:</strong>
        {{ description.sexo || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Estatura:</strong>
        {{ description.estatura || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Nacionalidad:</strong>
        {{ description.nacionalidad || 'No disponible' }}
      </div>
      <div class="mb-2">
        <strong>Grupo Sanguíneo:</strong>
        {{ description.grupo_sanguineo || 'No disponible' }}
      </div>
    </div>
  
    <div class="col-md-4">
      <p class="fw-bold">Foto selfie</p>
      <div class="mb-2">
        <strong>Descripción:</strong>
        {{ description.descripcion || 'No disponible' }}
      </div>
    </div>
  </div>