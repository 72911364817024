<section *ngIf="data" class="p-3 shadow-sm rounded section-today-main">
    <div class="card-today-main">
      <div class="card-content-title lh-1">
        <h1 class="fs-4">{{ title }}</h1>
        <span class="span-title">{{ subtitle }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <div class="p-4 card-today shadow-sm" [ngClass]="colorClass">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-chart-simple" style="color: #000000"></i>
          </div>
          <h4>{{ data.current.total_sales | currency : "$" : "symbol" : "1.0-0" }}</h4>
          <h5>Ventas totales</h5>
          <span>{{ calculateChange(data.current.total_sales, data.previous.total_sales, MetricCategory.SALES) }}</span>
        </div>
        <div class="p-4 card-today shadow-sm" [ngClass]="colorClass">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-box fa-sm" style="color: #000000"></i>
          </div>
          <h4>{{ data.current.total_orders }}</h4>
          <h5>Total de órdenes</h5>
          <span>{{ calculateChange(data.current.total_orders, data.previous.total_orders, MetricCategory.ORDERS) }}</span>
        </div>
        <div class="p-4 card-today shadow-sm" [ngClass]="colorClass">
          <div class="ball d-flex justify-content-center align-items-center mb-2">
            <i class="fa-solid fa-person" style="color: #000000"></i>
          </div>
          <h4>{{ data.current.new_customers }}</h4>
          <h5>Nuevos Clientes</h5>
          <span>{{ calculateChange(data.current.new_customers, data.previous.new_customers, MetricCategory.CLIENTS) }}</span>
        </div>
      </div>
    </div>
</section>