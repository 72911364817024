import { Component, Input } from '@angular/core';
import { IADescription } from '../../../../../administrative/customers/interfaces/customer-interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ia-extraction',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ia-extraction.component.html',
  styleUrl: './ia-extraction.component.scss'
})
export class IaExtractionComponent {

  /** 
   * Descripción extraída por la IA que contiene información del documento
   * y resultados de la comparación biométrica
   */
  @Input() description!: IADescription;

  /**
   * Calcula el porcentaje de confianza basado en el valor decimal proporcionado
   * @param confidence - Valor decimal entre 0 y 1 que representa el nivel de confianza
   * @returns Porcentaje de confianza entre 0 y 100
   */
  getConfidencePercentage(confidence: number | null): number {
    if (confidence === null || confidence === undefined) return 0;
    return confidence * 100;
  }

}
