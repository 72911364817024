<app-breadcrumb title="Panel de solicitudes" [items]="breadcrumbItems" />
<div class="px-3">
      <div class="card-shadow">
            <div class="card-body">
                  <app-action-buttons [dataFilter]="dataFilter" (eventSearchAction)="handleSearchAction($event)"></app-action-buttons>
                  <div class="row mt-3">
                        <div class="col-12 d-flex flex-wrap">
                              <div class="col-12 col-md-3 mb-4">
                                    <app-credit-request-group [type]="'received'"
                                          [title]="'Recibidas'"></app-credit-request-group>
                              </div>
                              <div class="col-12 col-md-3 mb-4">
                                    <app-credit-request-group [type]="'study'"
                                          [title]="'En estudio'"></app-credit-request-group>
                              </div>
                              <div class="col-12 col-md-3 mb-4">
                                    <app-credit-request-group [type]="'preaproved'"
                                          [title]="'Preaprobadas'"></app-credit-request-group>
                              </div>
                              <div class="col-12 col-md-3 mb-4">
                                    <app-credit-request-group [type]="'managed'"
                                          [title]="'Gestionadas'"></app-credit-request-group>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</div>