import { Routes } from '@angular/router';

export const CommercesRoutes: Routes = [
      {
            path: 'listado',
            loadComponent: () => import('../commerces/pages/commerces-list-index/commerces-list-index.component')
      },
      {
            path: 'detalle/:id',
            loadComponent: () => import('../commerces/pages/commerces-detail/commerces-detail.component')
      }
];
