import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderService } from '../../../../shared/services/loader.service';
import { toggleLoader } from '../../../../shared/helpers/functions-helper.service';
import { ModalServiceAlert } from '../../../../shared/components/modal-alert/service/modal-alert.service';
import { WebhookService } from '../../services/webhook.service';
import { CommonModule } from '@angular/common';

@Component({
      selector: 'app-webhook-index',
      templateUrl: './webhook-index.component.html',
      styleUrls: ['./webhook-index.component.scss'],
      standalone: true,
      imports: [ReactiveFormsModule, FormsModule, CommonModule]
})
export class WebhookIndexComponent {
      form!: FormGroup;
      help: string = '';
      message_error: string = '';
      type_error: string = '';
      showTypeSubscriptions: boolean = false;
      webhooks: any = [];
      myWebhooks: any = [];
      initial_querys_total: number = 3;
      current_querys_total: number = 0;
      example: any = null;

      constructor(
            private fb: FormBuilder,
            public loaderService: LoaderService,
            private ModalServiceAlert: ModalServiceAlert,
            private webhookService: WebhookService
      ) { }

      /**
       * Inicializa el componente, carga las suscripciones y configura el formulario.
       */
      ngOnInit() {
            toggleLoader(this.loaderService, true, 'Cargando las tipo de suscripciones y las que tienes actualmente activas');
            this.webhookService.allTypeSubscriptions().subscribe((res: any) => {
                  this.webhooks = res.data
                  this.stopLoader();
            });
            this.webhookService.whichOnesSubscribed().subscribe((res: any) => {
                  this.myWebhooks = res.data
                  this.stopLoader();
            });
            this.webhookService.isUserSubscribed().subscribe((res: any) => {
                  this.showTypeSubscriptions = res.isSubscribed;
                  this.stopLoader();
            });
            this.form = this.fb.group({
                  url: this.fb.control(''),
                  token: this.fb.control('')
            });
      }

      /**
       * Detiene el loader cuando todas las consultas iniciales han terminado.
       */
      stopLoader() {
            this.current_querys_total++;
            if (this.initial_querys_total === this.current_querys_total) {
                  toggleLoader(this.loaderService, false);
                  this.checkSubscriptionStatus();
            }
      }

      /**
       * Verifica el estado de suscripción de los webhooks.
       */
      checkSubscriptionStatus(): void {
            this.webhooks.forEach((webhook: any) => {
                  const isSubscribed = this.myWebhooks.some((myWebhook: any) => myWebhook.type_subscription_id === webhook.id);
                  if (isSubscribed) {
                        webhook.subscribed = true;
                  }
            });
      }

      /**
       * Muestra la ayuda para un tipo específico.
       * @param {string} type - El tipo de ayuda a mostrar.
       */
      showHelp(type: string) {
            this.help = type;
      }

      /**
       * Formatea la URL eliminando espacios en blanco.
       */
      formatUrl() {
            var url = this.form.get('url')?.value;
            url = url.replace(' ', '');
            this.form.get('url')?.setValue(url);
      }

      /**
       * Suscribe al webhook si el formulario es válido.
       */
      subscribeWebhook() {
            toggleLoader(this.loaderService, true);
            if (this.validateForm()) {
                  this.webhookService.subscribeWebhook(this.form.value).subscribe((res: any) => {
                        toggleLoader(this.loaderService, false);
                        if (res.ok) {
                              this.ModalServiceAlert.openModal('¡Alerta!', res.message, 'success');
                              this.showTypeSubscriptions = true;
                              this.resetForm();
                        } else {
                              this.ModalServiceAlert.openModal('Error!', res.message, 'error');
                              this.message_error = res.message;
                              this.type_error = res.type;
                        }
                  })
            }
      }

      /**
       * Valida el formulario antes de la suscripción.
       * @returns {boolean} - Verdadero si el formulario es válido, falso en caso contrario.
       */
      validateForm() {
            if (this.form.get('url')?.value === '') {
                  this.message_error = 'El campo URL es obligatorio';
                  this.type_error = 'url';
                  toggleLoader(this.loaderService, false);
                  return false;
            }

            if (this.form.get('token')?.value === '') {
                  this.message_error = 'El campo Token es obligatorio';
                  this.type_error = 'token';
                  toggleLoader(this.loaderService, false);
                  return false;
            }

            this.type_error = '';
            return true;
      }

      /**
       * Reinicia el formulario y limpia los mensajes de error.
       */
      resetForm() {
            this.help = '';
            this.message_error = '';
            this.form.reset();
      }

      /**
       * Añade o elimina una suscripción.
       * @param {number} index - El índice del webhook en el array.
       * @param {boolean} subscribed - El nuevo estado de suscripción.
       */
      addRemoveSubscription(index: number, subscribed: boolean) {
            toggleLoader(this.loaderService, true);
            this.webhooks[index].subscribed = subscribed;
            this.webhookService.changeStatusTypeWebhook(this.webhooks[index]).subscribe((res: any) => {
                  toggleLoader(this.loaderService, false);
                  this.myWebhooks = res.data
                  this.checkSubscriptionStatus();
            });
      }

      /**
       * Muestra un ejemplo específico.
       * @param {any} exampleChoosed - El ejemplo seleccionado.
       */
      showExample(exampleChoosed: any) {
            this.example = exampleChoosed;
      }

      /**
       * Convierte un texto JSON a un objeto JavaScript.
       * @param {string} text - El texto JSON a convertir.
       * @returns {any} - El objeto JavaScript resultante.
       */
      returnExampleAsJson(text: string) {
            return JSON.parse(text);
      }
}
