<div class="d-flex gap-2">
    <div class="card mb-2 w-100 border border-success border-opacity-25 rounded position-relative">
      <div class="card-body p-3">
        <h4 class="card-title">Cupo Aprobado</h4>
        <h2>${{ totals().cupoAprobado| number }}</h2>
        <div>
          <p class="card-text">¡Total cupo aprobado del cliente!</p>
          <p *ngIf="mora()" class="fw-bold text-danger">
            Cupo bloqueado por mora
          </p>
        </div>
      </div>
      <div class="position-absolute end-0 top-0 pe-2" *ngIf="role() === 1">
        <button type="button" (click)="customerEditCupo()"
          class="btn btn-info btn-xs w-100 mt-2 py-2 d-flex align-items-center justify-content-center"
          [disabled]="customer().pagare_signed === 'No' || mora()" placement="top" ngbTooltip="Editar cupo">
          <i class="fa-solid fa-wand-magic-sparkles"></i>
        </button>
      </div>
    </div>
    <div class="card mb-2 w-100 border border-success border-opacity-25 rounded position-relative">
      <div class="card-body p-3">
        <h4 class="card-title">Cupo Disponible</h4>
        <h2>${{ totals().cupoDisponibleCompras | number }}</h2>
        <div>
          <p class="card-text">¡Total cupo disponible para compras!</p>
          <p *ngIf="mora()" class="fw-bold text-danger">
            Cupo bloqueado por mora
          </p>
        </div>
      </div>
    </div>
  </div>