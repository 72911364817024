<div class="container webhooks">
      <h1>Suscribete a nuestros webhooks</h1>
      <p>
            Recibe notificaciones automáticas sobre eventos importantes directamente en tu sistema.
            <a href="/assets/download/Guía webhook Ziro.pdf" target="_blank" class="text-info">
                  <u>Descargar Guía</u>
            </a>
      </p>

      <form class="mt-5 row" (ngSubmit)="subscribeWebhook()" [formGroup]="form">
            <div class="form-group col-12 col-md-6">
                  <label for="url">URL</label>
                  <input (keyup)="formatUrl()" placeholder="https://ejemplo-url.com" type="text" class="form-control"
                        id="url" formControlName="url">
                  <i (mouseover)="showHelp('url')" (mouseout)="showHelp('')" class="help fa-solid fa-circle-info"></i>
                  @if (type_error === 'url' || type_error === 'both') {
                  <i (mouseover)="showHelp('url-error')" (mouseout)="showHelp('')"
                        class="help-error fa-solid fa-xmark"></i>
                  }
                  @if (help === 'url') {
                  <div class="card-info">
                        <p>Ingresa la URL a la que se enviarán las notificaciones.</p>
                  </div>
                  }
                  @if (help === 'url-error') {
                  <div class="card-info-error">
                        <p>{{ message_error }}</p>
                  </div>
                  }
            </div>

            <div class="form-group col-12 col-md-6">
                  <label for="token">TOKEN</label>
                  <input type="text" class="form-control" id="token" formControlName="token">
                  <i (mouseover)="showHelp('token')" (mouseout)="showHelp('')" class="help fa-solid fa-circle-info"></i>
                  @if (type_error === 'token' || type_error === 'both') {
                  <i (mouseover)="showHelp('token-error')" (mouseout)="showHelp('')"
                        class="help-error fa-solid fa-xmark"></i>
                  }
                  @if (help === 'token') {
                  <div class="card-info">
                        <p>Ingresa el token que se enviará en la cabecera de la petición.</p>
                  </div>
                  }
                  @if (help === 'token-error') {
                  <div class="card-info-error">
                        <p>{{ message_error }}</p>
                  </div>
                  }
            </div>

            <div class="button-save">
                  <button class="btn btn-info mt-2">Verificar y guardar</button>
            </div>
      </form>

      @if (showTypeSubscriptions) {
      <div class="webhooks-list mt-5">
            <h2>Webhooks suscritos</h2>
            <div class="no-webhooks">
                  <p>Tienes {{ myWebhooks.length }} webhooks suscritos.</p>
            </div>
            <table class="table">
                  <thead>
                        <tr>
                              <th scope="col">Nombre</th>
                              <th scope="col">Prueba</th>
                              <th scope="col">Suscribir</th>
                        </tr>
                  </thead>
                  <tbody>
                        @for (webhook of webhooks; track webhook.id; let i = $index) {
                        <tr>
                              <td>{{ webhook.name }}</td>
                              <td>
                                    <button (click)="showExample(webhook)" data-bs-toggle="modal"
                                          data-bs-target="#modalExanpleWebhooks" class="btn btn-sm btn-info">
                                          Ejemplo
                                    </button>
                              </td>
                              <td>
                                    <button class="btn btn-sm" (click)="addRemoveSubscription(i, !webhook.subscribed)"
                                          [ngClass]="{ 'btn-info': !webhook.subscribed, 'btn-warning': webhook.subscribed }">
                                          Suscribirse
                                    </button>
                              </td>
                        </tr>
                        }
                  </tbody>
            </table>
      </div>
      }
</div>

<!-- Modal para mostrar ejemplos -->
<div class="modal fade" id="modalExanpleWebhooks" tabindex="-1" aria-labelledby="modalExanpleWebhooksLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-xl">
            <div class="modal-content">
                  <div class="modal-header">
                        <h5 class="modal-title" id="modalExanpleWebhooksLabel">{{ example?.name }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                        <pre><code>{{ returnExampleAsJson(example?.example) | json }}</code></pre>
                  </div>
            </div>
      </div>
</div>
