import { Component, input, InputSignal } from '@angular/core';
import { CustomerHistorialInterface } from '../../../../../interfaces';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-customer-inactive-or-active',
  standalone: true,
  imports: [ CommonModule],
  templateUrl: './customer-inactive-or-active.component.html',
  styleUrl: './customer-inactive-or-active.component.scss'
})
export class CustomerInactiveOrActiveComponent {

  /**
   * @property {InputSignal<CustomerHistorialInterface>} customer
   * @description Señal de entrada requerida que contiene la información del cliente.
   * @type {InputSignal<CustomerHistorialInterface>}
   */
  customer: InputSignal<CustomerHistorialInterface> = input.required<CustomerHistorialInterface>();

}
