import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../auth/login/services/auth.service';
import { LoaderService } from '../../../services/loader.service';
import { toggleLoader } from '../../../helpers/functions-helper.service';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent {

  /** @property {string} userName - Nombre del usuario actual. */
  public userName!: string;

  /** @property {Subscription | undefined} logoutSubscription - Suscripción al observable de cierre de sesión. */
  public logoutSubscription: Subscription | undefined;

  /**
   * @constructor
   * @param {Router} router - Servicio de enrutamiento de Angular.
   * @param {AuthService} authService - Servicio de autenticación.
   * @param {LoaderService} loaderService - Servicio para manejar el loader.
   */
  constructor(
    public router: Router,
    private authService: AuthService,
    private loaderService: LoaderService
  ){}

  /**
   * @method logoutFunc
   * @description Realiza el proceso de cierre de sesión.
   * @returns {void}
   */
  public logoutFunc(): void {
    console.log('Se presionó el botón de cerrar sesión');                                                  
    toggleLoader(this.loaderService, true);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.logoutSubscription = this.authService.logout()
      .pipe(
        finalize(() => {
          toggleLoader(this.loaderService, false);
          this.router.navigate(['/iniciar-sesion']);
        })
      )
      .subscribe({
        error: (error) => {
          console.error('Error durante el cierre de sesión:', error);
        }
      });
  }

  /**
   * @method ngOnDestroy
   * @description Método del ciclo de vida de Angular que se ejecuta al destruir el componente.
   * Cancela la suscripción al observable de cierre de sesión si existe.
   * @returns {void}
   */
  ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

}
