<div class="content-table">
  <div class="hidden-table">
      @if (monthData.length > 0) {
            <table class="table">
              <thead>
                <tr>
                  <th>Mes</th>
                  <th>Corriente</th>
                  <th>Mora</th>
                </tr>
              </thead>
              <tbody>
                @for (item of monthData; track item) {
                <tr>
                  <td class="align-middle">{{ item.month }}</td>
                  <td>
                    <input step="0.01" class="form-control form-control-sm w-25" type="number"
                      [(ngModel)]="item.current_rate" />
                  </td>
                  <td>
                    <input step="0.01" class="form-control form-control-sm w-25" type="number"
                      [(ngModel)]="item.default_rate" />
                  </td>
                </tr>
                }
              </tbody>
            </table>
            <button (click)="saveData()" class="btn button btn-success">
              Guardar Tasa de mora
            </button>
      } @else {
            <div class="d-flex justify-content-center align-items-center">
                  <div class="p-4">
                        <app-loader-wait></app-loader-wait>
                  </div>
            </div>
      }
  </div>
</div>
