import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { ModalAlertComponent } from './shared/components/modal-alert/modal-alert.component';
import { ModalServiceAlert } from './shared/components/modal-alert/service/modal-alert.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoaderComponent, ModalAlertComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent  { 

  
}
