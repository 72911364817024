import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchStateService {
  /**
   * @private
   * @type {BehaviorSubject<boolean>}
   * @description BehaviorSubject que mantiene el estado de la bandera de búsqueda del cliente.
   */
  private flagSearchCustomerSubject = new BehaviorSubject<boolean>(false);

  /**
   * @public
   * @type {Observable<boolean>}
   * @description Observable que emite el estado actual de búsqueda del cliente.
   */
  flagSearchCustomer$ = this.flagSearchCustomerSubject.asObservable();

  /**
   * @public
   * @method setFlagSearchCustomer
   * @param {boolean} value - El nuevo valor para el estado de búsqueda del cliente.
   * @description Actualiza el estado de búsqueda del cliente.
   */
  setFlagSearchCustomer(value: boolean) {
    this.flagSearchCustomerSubject.next(value);
  }
}