import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardCollectionsComponent } from './pages/dashboard-collections-index/dashboard-collections.component';

export const DashboardCollectionsRoutes: Routes = [
      {
            path: 'arbol-gestion/:id',
            component: DashboardCollectionsComponent,
      },
];
