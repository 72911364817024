<div class="modal-header">
    <h4 class="modal-title">Comentarios y Observaciones</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    @if (roleIsAdmin) {
        <h5 class="fw-bold mb-3">¿Qué sucede con esta solicitud?</h5>
        <div class="mb-3">
            <label for="reasonSelect" class="form-label">Selecciona un motivo</label>
            <select id="reasonSelect" class="form-select" [(ngModel)]="selectReason">
                <option value="Las fotos del documento no son legibles">Las fotos del documento no son legibles</option>
                <option value="Una de las referencias no contesta el teléfono">Una de las referencias no contesta el teléfono</option>
                <option value="Hay problemas con el documento de identidad">Hay problemas con el documento de identidad</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="commentTextarea" class="form-label">Comentarios</label>
            <textarea id="commentTextarea" class="form-control" rows="3" placeholder="Describe tu observación para esta solicitud" [(ngModel)]="textareaContent"></textarea>
        </div>
        <div class="text-end mb-3">
            <button type="button" class="btn btn-primary" (click)="saveComments()">
                Guardar <i class="fas fa-save"></i>
            </button>
        </div>
        <hr>
    }
    
    <div class="card">
        <div class="card-header">
            <h5 class="mb-0">Observaciones previas:</h5>
        </div>
        <div class="card-body">
            @if (listComents.length) {
                @for (comment of listComents; track comment) {
                    <div class="media mb-3">
                        <div class="recent-circle bg-primary me-3"></div>
                        <div class="media-body">
                            <h6 class="font-roboto mb-1">{{ comment.type }}</h6>
                            <p class="mb-1"><i class="fas fa-comments me-2"></i>{{ comment.comment }}</p>
                            <small><i class="fas fa-clock me-2"></i>{{ comment.created }}</small>
                        </div>
                    </div>
                }
            } @else {
                <p class="text-center mb-0">No hay comentarios u observaciones</p>
            }
        </div>
    </div>
</div>