import { Routes } from '@angular/router';

export const ParametersRoutes: Routes = [
      {
            path: '',
            loadComponent: () => import('./pages/parameters-index/parameters-index.component')
      },
      {
            path: 'departamentos',
            loadComponent: () => import('./pages/parameters-deparments-index/parameters-deparments-index.component')
      },
      {
            path: 'ciudades',
            loadComponent: () => import('./pages/parameters-cities-index/parameters-cities-index.component')
      }
];
