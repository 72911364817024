<div class="sidebar">
  <!-- Logo y botón de cierre -->
  <div class="header-sidebar">
    <div class="logo-container-side">
      <a href="/" class="content-img">
        <img class="img-fluid" src="../../../../assets/img-news/logo-dark.svg" alt="Ziro Logo">
      </a>
      <button class="btn-close-sidebar" (click)="sidebarToggle()">
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
  </div>
  
  <!-- Resto del contenido del sidebar -->
  <ul class="nav nav-pills flex-column mb-auto px-3">
    @for (menu of menuItems; track $index) {
      <li class="nav-item">
        <!-- Menu principal -->
        <a class="nav-link d-flex justify-content-between align-items-center" [class.active]="menu.active" (click)="toggleNavActive(menu)">
          <span class="d-flex align-items-center">
            <i class="{{menu.icon}} me-2"></i> <!-- Icono del menú con margen a la derecha -->
            {{menu.title}}
          </span>
          @if (menu.children && menu.children.length > 0) {
            <i class="fa-solid" [ngClass]="menu.active ? 'fa-angle-down' : 'fa-angle-right'"></i>
          }
        </a>
        
        <!-- Si tiene hijos, mostrar el submenú -->
        @if (menu.children && menu.active) {
          <ul class="submenu ps-4">
            @for (child of menu.children; track $index) {
              <li class="nav-item">
                <a [routerLink]="child.path" 
                   class="nav-link-2"
                   [class.active]="child.active"
                   (click)="toggleNavActive(child)">
                  <span class="submenu-item">{{child.title}}</span>
                </a>
              </li>
            }
          </ul>
        }
      </li>
    }
  </ul>
</div>