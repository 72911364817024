@if (customer().pagare_signed === 'No') {
    <div class="card mb-2 w-100 border border-success border-opacity-25 rounded">
    <div class="d-flex justify-content-between gap-2">
      <div class="card-body p-3">
        <div class="d-flex align-items-center gap-2">
          <h4 class="card-title text">&nbsp;Cupo no activado</h4>
        </div>
        <div>
          <p class="card-text">
            &nbsp; El cliente aún no ha firmado el pagaré.
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center p-3">
        <i class="fa fa-exclamation-circle mr-2 text-danger" style="font-size: 30px"></i>
      </div>
    </div>
  </div>
}
  
@if (customer().pagare_signed == 'Si' && customer().state == 'Inactivo') {
    <div class="card mb-2 w-100 border border-success border-opacity-25 rounded">
    <div class="d-flex justify-content-between gap-2">
      <div class="card-body p-3">
        <div class="d-flex align-items-center gap-2">
          <h4 class="card-title text">&nbsp;Firma completada, activación pendiente</h4>
        </div>
        <div>
          <p class="card-text">
            &nbsp; El cliente ha firmado el pagaré, pero la activación está pendiente.
          </p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center p-3">
        <i class="fa fa-exclamation-circle mr-2 text-warning" style="font-size: 30px"></i>
      </div>
    </div>
  </div>

}