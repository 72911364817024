import { Routes } from '@angular/router';

export const SiigoRoutes: Routes = [
      {
            path: 'clientes',
            loadComponent: () => import('./siigo-customers/pages/siigo-customers-index/siigo-customers-index.component')
      },
      {
            path: 'pagos',
            loadComponent: () => import('./siigo-payments/pages/siigo-payments-index/siigo-payments-index.component')
      }
];
