import { Component, Input } from '@angular/core';
import { DataMiniCards, FieldTitles } from '../../interfaces/commerce-dashboard.interface';
import { CommonModule } from '@angular/common';
import { NumberFormatPipe } from '../../../../payment-general/pipes/number-format.pipe';

@Component({
  selector: 'app-mini-cards-dashboard',
  standalone: true,
  imports: [CommonModule, NumberFormatPipe],
  templateUrl: './mini-cards-dashboard.component.html',
  styleUrl: './mini-cards-dashboard.component.scss'
})
export class MiniCardsDashboardComponent {

   /**
    * Títulos para los diferentes campos mostrados en las mini tarjetas
    * @type {FieldTitles}
    */
   public fieldTitles: FieldTitles = {
    totalCustomers: 'Total usuarios aprobados',
    withdrawnCredits: 'Total créditos retirados', 
    withdrawnCreditsCount: 'Numero de créditos retirados',
    creditsRequestsCount: 'Total usuarios registrados'
  };

  /**
   * Clases de colores asociadas a cada tipo de tarjeta
   * @type {Record<string, string>}
   */
  public colorClasses = {
    'withdrawnCredits': 'yellow',
    'totalCustomers': 'purple', 
    'creditsRequestsCount': 'pink',
    'withdrawnCreditsCount' : 'blue',
  };
  
  /**
   * Datos que se mostrarán en las mini tarjetas del dashboard
   * @type {DataMiniCards}
   */
  @Input() public commerceDashboardCard!:DataMiniCards
  




}
