<table class="table mt-2">
    <thead>
        <tr>
            <th>Obligación</th>
            <th>Valor Crédito</th>
            <th>F. pago cliente</th>
            <th>Coutas</th>
            <th>V. Cuota</th>
            <th>Abonado</th>
            <th>Saldo</th>
            <th>Dias mora</th>
            <th>V. mora</th>
            <th>Saldo total</th>
            <th>Estado</th>
        </tr>
    </thead>
    <tbody>
        <!-- si hay registros -->
        <ng-container *ngIf="historyCreditsCustomerList && historyCreditsCustomerList.length > 0;">
            <tr *ngFor="let item of historyCreditsCustomerList">
                <td>{{item.credits_code_pay }}</td>
                <td>{{item.credits_value | currency:'$':'symbol':'1.0-0' }}</td>
                <td>{{ item.credits_deadline | defaultIfEmpty:'No disponible' }}
                </td>
                <td>{{item.credits_number_fee}}</td>
                <td>{{item.credits_quota_value | currency:'$':'symbol':'1.0-0'
                    }}</td>
                <td>{{item.total_abonado | currency:'$':'symbol':'1.0-0' }}</td>
                <td>{{item.credits_value_pending | currency:'$':'symbol':'1.0-0'
                    }}</td>
                <td>{{ item.dias_mora }}</td>
                <td>{{ item.value_intereses_mora }}</td>
                <td>{{item.saldo_total| currency:'$':'symbol':'1.0-0' }}</td>
                <td>
                    <span class="badge badge-{{ item.credits_states_color }}">
                        {{ item.credits_states_name }}
                    </span>
                </td>
            </tr>
        </ng-container>

        <!-- si no hay registros -->
        <ng-container *ngIf="!(historyCreditsCustomerList && historyCreditsCustomerList.length > 0)">
            <tr class="text-center">
                <td colspan="100%" scope="row">
                    <i class="fas fa-info-circle"></i>
                    No se encontraron registros.
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>