import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WithdrawCreditComponent } from '../withdraw-credit/withdraw-credit.component';
import { finalize, Subject, Subscription, takeUntil } from 'rxjs';
import { CommerceInterface, CupoInfoInterface, CustomerHistorialCredit, CustomerHistorialInterface, TotalCreditInterface, UserInterface } from '../../../interfaces';
import { CustomerService } from '../../../../customer/services/customer.service';
import { CommercesService } from '../../../../administrative/commerces/services/commerces.services';
import { LoaderService } from '../../../services/loader.service';
import { ModalServiceAlert } from '../../modal-alert/service/modal-alert.service';
import { AuthService } from '../../../../auth/login/services/auth.service';
import { Router } from '@angular/router';
import { toggleLoader } from '../../../helpers/functions-helper.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomersDataComponent } from './components/customers-data/customers-data.component';
import { CustomerInactiveOrActiveComponent } from './components/customer-inactive-or-active/customer-inactive-or-active.component';
import { CustomerQuotaComponent } from "./components/customer-quota/customer-quota.component";
import { CustomersEditComponent } from '../../../../administrative/customers/components/modals/customers-edit/customers-edit.component';
import { SearchStateService } from '../../../services/flag-customer.service';

@Component({
  selector: 'app-customers-search',
  standalone: true,
  imports: [CommonModule, FormsModule, WithdrawCreditComponent, NgSelectModule, CustomersDataComponent, CustomerInactiveOrActiveComponent, CustomerQuotaComponent],
  templateUrl: './customers-search.component.html',
  styleUrl: './customers-search.component.scss'
})
export class CustomersSearchComponent implements OnInit {

  /**
   * @property {TemplateRef<CustomersSearchComponent>} modalCustomersSearchContent - Referencia al template del modal de búsqueda de clientes.
   */
  @ViewChild("modalCustomersSearch")
  public modalCustomersSearchContent!: TemplateRef<CustomersSearchComponent>;

  /**
   * @property {WithdrawCreditComponent} withdrawCreditModal - Referencia al componente de retiro de crédito.
   */
  @ViewChild(WithdrawCreditComponent) withdrawCreditModal!: WithdrawCreditComponent;

  /**
   * @property {NgbModalRef} modalRef - Referencia al modal abierto.
   */
  public modalRef!: NgbModalRef;

  /**
   * @property {Subject<void>} destroy$ - Subject para manejar la desuscripción de los observables.
   */
  private destroy$ = new Subject<void>();

  /**
   * @property {CustomerHistorialInterface | null} customer - Información del cliente.
   */
  public customer: CustomerHistorialInterface | null = null;

  /**
   * @property {CustomerHistorialCredit[]} creditsHistorial - Historial de créditos del cliente.
   */
  public creditsHistorial: CustomerHistorialCredit[] = [];

  /**
   * @property {string} customerIdentification - Identificación del cliente.
   */
  public customerIdentification: string = "";

  /**
   * @property {CommerceInterface[]} shopsCommerces - Lista de sucursales comerciales.
   */
  public shopsCommerces: CommerceInterface[] = [];

  /**
   * @property {number} shopCommerceId - ID de la sucursal comercial seleccionada.
   */
  public shopCommerceId!: number | undefined;

  /**
   * @property {boolean} flagSearchCustomer - Bandera para indicar si se ha realizado una búsqueda de cliente.
   */
  public flagSearchCustomer: boolean = false;

  /**
   * @property {TotalCreditInterface} totals - Totales de créditos del cliente.
   */
  public totals: TotalCreditInterface = {
    processPending: 0,
    reject: 0,
    approvedWithoutDisbursement: 0,
    activeCredits: 0,
    cupoAprobado: 0,
    cupoDisponibleCompras: 0
  }

  /**
   * @property {boolean} mora - Indica si el cliente está en mora.
   */
  public mora: boolean = false;

  /**
   * @property {UserInterface} user - Información del usuario actual.
   */
  public user!: UserInterface;

  /**
   * @property {boolean} roleCanWithdrawCreditAction - Indica si el rol del usuario puede realizar retiros de crédito.
   */
  public roleCanWithdrawCreditAction: boolean = false;

  /**
   * @property {CupoInfoInterface} cupoInfo - Información del cupo de crédito del cliente.
   */
  public cupoInfo: CupoInfoInterface = {
    customerId: 0,
    approved: 0,
    actived: '',
    customerIdentification: '',
    customerEmail: '',
    fullName: '',
    newApproved: 0
  };

  /**
   * @property {boolean} cupoEditButton - Indica si se debe mostrar el botón de edición de cupo.
   */
  public cupoEditButton: boolean = false;

  /**
   * @property {number} role - ID del rol del usuario actual.
   */
  public role!: number;

  /**
   * @property {number} userId - ID del usuario actual.
   */
  public userId!: number;


  /**
   * @property {Subscription} subscription - Suscripción para manejar el estado de búsqueda de cliente.
   */
  private subscription!: Subscription;


  /**
   * @constructor
   * @param {NgbModal} modalService - Servicio para manejar modales.
   * @param {CustomerService} customerService - Servicio para manejar información de clientes.
   * @param {CommercesService} shopCommerceService - Servicio para manejar información de comercios.
   * @param {LoaderService} loaderService - Servicio para manejar el loader.
   * @param {ModalServiceAlert} modalAlert - Servicio para manejar alertas modales.
   * @param {AuthService} authService - Servicio de autenticación.
   * @param {Router} router - Servicio de enrutamiento.
   */
  constructor(
    private modalService: NgbModal,
    private customerService: CustomerService,
    private shopCommerceService: CommercesService,
    private loaderService: LoaderService,
    private modalAlert: ModalServiceAlert,
    private authService: AuthService,
    private router: Router,
    private searchStateService: SearchStateService
  ){
    this.user = this.authService.getUser();
    this.role = this.user.role_id;
    this.userId = JSON.parse(localStorage.getItem('user') || '{}').id;
  }

  /**
   * @method ngOnInit
   * @description Método del ciclo de vida que se ejecuta al inicializar el componente.
   */
  ngOnInit(): void {
    this.roleCanWithdrawCredit();
    this.subscription = this.searchStateService.flagSearchCustomer$.subscribe(
      value => {
        this.flagSearchCustomer = value;
      }
    );
  }

  /**
   * @method ngOnDestroy
   * @description Método del ciclo de vida que se ejecuta al destruir el componente.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * @method onInputChange
   * @description Maneja el cambio en el input de cupo.
   * @param {Event} event - Evento de cambio en el input.
   */
  public onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/[^\d]/g, '');
    input.value = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.cupoInfo.newApproved = parseFloat(inputValue);
    this.cupoEditButton = this.cupoInfo.approved !== this.cupoInfo.newApproved && inputValue !== '';
  }

  /**
   * @method roleCanWithdrawCredit
   * @description Verifica si el rol del usuario puede realizar retiros de crédito.
   */
  public roleCanWithdrawCredit(): void {
    this.roleCanWithdrawCreditAction = this.authService.roleCanWithdrawCredit();
  }

  /**
   * @method openModalCustomerSearch
   * @description Abre el modal de búsqueda de clientes.
   */
  public openModalCustomerSearch(): void {
    // Limpiar todos los valores
    this.customerIdentification = '';
    this.shopCommerceId = undefined;
    this.cupoInfo = {
        customerId: 0,
        approved: 0,
        actived: '',
        customerIdentification: '',
        customerEmail: '',
        fullName: '',
        newApproved: 0
    };
    this.flagSearchCustomer = false;
    this.customer = null;
    this.creditsHistorial = [];
    this.totals = {
        processPending: 0,
        reject: 0,
        approvedWithoutDisbursement: 0,
        activeCredits: 0,
        cupoAprobado: 0,
        cupoDisponibleCompras: 0
    };
    this.mora = false;
    
    // Obtener las sucursales después de limpiar
    this.getShopCommerces();
  }

  /**
   * @method getShopCommerces
   * @description Obtiene la lista de sucursales comerciales.
   */
  public getShopCommerces(): void {
    toggleLoader(this.loaderService, true);
    this.shopCommerceService.getShopCommercesList()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          toggleLoader(this.loaderService, false);
          if (!response.ok) {
            this.modalAlert.openModal('Error', 'Ocurrió un error al obtener el listado de sucursales', 'error');
          } else {
            this.shopsCommerces = response.data;
            this.initializeEnvironments();
            this.openModal();
          }
        },
        error: (error) => {
          toggleLoader(this.loaderService, false);
          this.modalAlert.openModal('Error', 'Ocurrió un error al obtener el listado de sucursales', 'error');
          console.error(error);
        }
      });
  }

  /**
   * @method openModal
   * @description Abre el modal de búsqueda de clientes.
   */
  public openModal(): void {
    this.modalRef = this.modalService.open(this.modalCustomersSearchContent, { size: 'lg' });
  }

  /**
   * @method closeModal
   * @description Cierra el modal de búsqueda de clientes.
   */
  closeModal(): void {
    this.modalRef.close();
  }

  /**
   * @method dismissModal
   * @description Descarta el modal de búsqueda de clientes.
   */
  dismissModal(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    } else {
      console.warn('modalRef is undefined');
    }
  }

  /**
   * @method searchCustomer
   * @description Busca la información de un cliente.
   */
  searchCustomer(): void {
    if (!this.cupoInfo.customerIdentification) {
      this.modalAlert.openModal('Error', 'La cédula es obligatoria', 'error');
      return;
    }
  
    toggleLoader(this.loaderService, true);
    this.customerService.getCustomerInfoHistorial(this.cupoInfo.customerIdentification)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => toggleLoader(this.loaderService, false))
      )
      .subscribe({
        next: (response) => {
          if (response.ok) {
            this.handleCustomerResponse(response.data);
          } else {
            this.modalAlert.openModal('Error', response.message, 'error');
          }
        },
        error: (error) => {
          console.error(error);
          this.modalAlert.openModal('Error', error.error.message || error.message, 'error');
        }
      });
  }

 /**
   * @method handleCustomerResponse
   * @description Maneja la respuesta de la búsqueda de cliente.
   * @param {any} data - Datos del cliente.
   */
 handleCustomerResponse(data: any): void {
  this.customer = data.customer;
  this.flagSearchCustomer = true;
  this.creditsHistorial = data.credits;
  this.totals = {
    processPending: data.totalProcessPending,
    reject: data.totalReject,
    approvedWithoutDisbursement: data.totalApprovedWithoutDisbursement,
    activeCredits: data.totalActiveCredits,
    cupoAprobado: data.totalCupoAprobado,
    cupoDisponibleCompras: data.totalCupoDisponibleCompras
  };
  this.mora = data.mora;
  this.updateCupoInfo();
}

  /**
   * @method updateCupoInfo
   * @description Actualiza la información del cupo del cliente.
   */
  updateCupoInfo(): void {
    if (this.customer) {
      this.cupoInfo = {
        customerId: this.customer.id,
        approved: this.totals.cupoAprobado,
        actived: this.customer.pagare_signed,
        customerIdentification: this.customer.identification,
        customerEmail: this.customer.email,
        fullName: `${this.customer.name} ${this.customer.last_name}`,
        newApproved: 0
      };
    }
  }

/**
   * @method customerEditCupo
   * @description Abre el modal para editar el cupo del cliente.
   * @param { CustomerHistorialInterface } customer - Información del cupo.
   */
customerEditCupo( customer: CustomerHistorialInterface ): void {

  const modalRef = this.modalService.open(CustomersEditComponent, { size: 'lg' });
  modalRef.componentInstance.customer =  customer;
  modalRef.result.then(() => this.searchCustomer());
}

 /**
   * @method initializeEnvironments
   * @description Inicializa las variables del entorno.
   */
 initializeEnvironments(): void {
  this.customer = null;
  this.totals = {
    processPending: 0,
    reject: 0,
    approvedWithoutDisbursement: 0,
    activeCredits: 0,
    cupoAprobado: 0,
    cupoDisponibleCompras: 0
  };
  this.mora = false;
}

/**
 * Confirma el retiro de crédito
 * @returns {void}
 */
confirmCreditWithdrawal(): void {
  if (typeof this.shopCommerceId === 'undefined') {
    this.modalAlert.openModal('Error', 'Debe seleccionar una sucursal para retirar crédito', 'error');
    return;
  }
  const buttonConfig = { acceptText: 'Retira credito', cancelText: 'Cancelar', showCancel: true };
  this.modalAlert.openModal(
    'Confirmación retiro de crédito',
    `¿Está seguro de realizar el retiro de crédito al cliente ${this.customer?.identification}, valor disponible compras: ${this.totals.cupoDisponibleCompras}?`,
    'warning',
    buttonConfig
  ).subscribe((response) => {
    if (response) {
      this.processWithdrawal();
    }
  });
}

/**
 * Procesa el retiro de crédito
 * @returns {void}
 */
processWithdrawal(): void {
  const ERROR_MESSAGES = {
    overdueCredits: 'El cliente tiene créditos en mora. No se puede procesar el retiro.',
    noPermission: 'No tiene permisos para realizar esta acción'
  };

  if (this.hasOverdueCredits()) {
    this.modalAlert.openModal('Error', ERROR_MESSAGES.overdueCredits, 'error');
    return;
  }

  if (!this.hasPermission()) {
    this.modalAlert.openModal('Error', ERROR_MESSAGES.noPermission, 'error');
    return;
  }

  this.processWithdrawalAction();
}

/**
 * Verifica si el cliente tiene créditos vencidos
 * @returns {boolean} True si tiene créditos vencidos, false en caso contrario
 * @private
 */
private hasOverdueCredits(): boolean {
  return this.checkForOverdueCredits();
}

/**
 * Verifica si el usuario tiene permiso para realizar el retiro
 * @returns {boolean} True si tiene permiso, false en caso contrario
 * @private
 */
private hasPermission(): boolean {
  return this.user.role_id === 6 || this.user.role_id === 1;
}

/**
 * Realiza la acción de procesar el retiro de crédito
 * @returns {void}
 * @public
 */
public processWithdrawalAction(): void {
  toggleLoader(this.loaderService, true);
  this.dismissModal();

  setTimeout(() => {
    this.withdrawCreditModal.formWithdrawCredit.reset();
    this.withdrawCreditModal.flagShowCodeInput = false;
    this.withdrawCreditModal.flagConfirmCreditAction = false;
    this.withdrawCreditModal.openModalWithdrawCreditComponent(
      this.totals.cupoDisponibleCompras,
      this.shopCommerceId,
      this.customer!
    );
    toggleLoader(this.loaderService, false);
  }, 100);
}

/**
 * Verifica si el cliente tiene créditos vencidos
 * @returns {boolean} True si tiene créditos vencidos, false en caso contrario
 * @public
 */
public checkForOverdueCredits(): boolean {
  if (!this.customer || !this.customer.credits) {
    return false;
  }

  const currentDate = new Date();
  return this.customer.credits.some(credit => {
    if (credit.credit_state_id === 3 && credit.deadline) {
      const deadlineDate = new Date(credit.deadline);
      if (!isNaN(deadlineDate.getTime())) {
        return deadlineDate < currentDate;
      }
    }
    return false;
  });
}

/**
 * Redirige al detalle de pago de un crédito específico
 * @param {number} creditId - ID del crédito
 * @returns {void}
 */
redirectToPaymentDetail(creditId: number): void {
  this.dismissModal();
  this.router.navigate(['/creditos/pago-detalle', creditId]);
}

/**
 * Cierra el modal y reinicia algunos valores
 * @param {any} modal - Referencia al modal a cerrar
 * @returns {void}
 */
closeWithModal(modal: any): void {
  modal.dismiss('Cancelar');
  this.shopCommerceId = undefined;
  this.cupoInfo = {
    customerId: 0,
    approved: 0,
    actived: '',
    customerIdentification: '',
    customerEmail: '',
    fullName: '',
    newApproved: 0
  };
  this.flagSearchCustomer = false;
  this.customer = null;
  this.creditsHistorial = [];
  this.totals = {
    processPending: 0,
    reject: 0,
    approvedWithoutDisbursement: 0,
    activeCredits: 0,
    cupoAprobado: 0,
    cupoDisponibleCompras: 0
  };
  this.mora = false;
}
}
