import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CreditRequestService } from "../../../../administrative/credits-requests/services/credit-request.service";
import { AuthService } from "../../../../auth/login/services/auth.service";
import { toggleLoader } from "../../../helpers/functions-helper.service";
import { LoaderService } from "../../../services/loader.service";
import { ModalServiceAlert } from "../../modal-alert/service/modal-alert.service";

@Component({
    selector: "app-credits-requests-comments",
    templateUrl: "./credits-requests-comments.component.html",
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbTooltipModule
    ],
})
export class CreditsRequestsCommentsComponent implements OnInit {
    @Input() creditRequestId!: number;
    textareaContent = '';
    selectReason = '';
    listComents: any[] = [];
    roleUser!: number;
    roleIsAdmin = false;

    constructor(
        public activeModal: NgbActiveModal,
        private authService: AuthService,
        private creditRequestService: CreditRequestService,
        private loaderService: LoaderService,
        private alertService: ModalServiceAlert
    ) { }

    /**
     * Inicializa el componente obteniendo los comentarios de la solicitud y verificando el rol del usuario.
     */
    ngOnInit(): void {
        this.getCommentsRequest();
        this.userIsRoleAdmin();
    }

    /**
     * Verifica si el usuario tiene un rol administrativo.
     */
    userIsRoleAdmin(): void {
        this.roleUser = this.authService.getUser().role_id;
        this.roleIsAdmin = this.authService.getRolesAdministrative().includes(this.roleUser);
    }

    /**
     * Guarda un nuevo comentario para la solicitud de crédito.
     * Muestra alertas de éxito o error según el resultado de la operación.
     */
    saveComments(): void {
        if (!this.textareaContent || !this.selectReason) {
            this.alertService.openModal("Error", "Por favor completa todos los campos.", "warning");
            return;
        }

        toggleLoader(this.loaderService, true);
        this.creditRequestService.saveCommentsCreditRequest(this.creditRequestId, this.textareaContent, this.selectReason).subscribe(
            () => {
                this.alertService.openModal("Éxito", "Se agregó un nuevo comentario.", "success");
                this.getCommentsRequest();
                this.textareaContent = '';
                this.selectReason = '';
            },
            (error) => {
                this.alertService.openModal("Error", "Ocurrió un error al guardar el comentario. Inténtalo nuevamente.", "error");
            }
        ).add(() => toggleLoader(this.loaderService, false));
    }

    /**
     * Obtiene la lista de comentarios para la solicitud de crédito actual.
     */
    getCommentsRequest(): void {
        toggleLoader(this.loaderService, true);
        this.creditRequestService.getCommentsCreditRequest(this.creditRequestId).subscribe(
            (response) => {
                this.listComents = response.data;
            }
        ).add(() => toggleLoader(this.loaderService, false));
    }
}
