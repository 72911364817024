import { Routes } from '@angular/router';

/**
 * Rutas para el módulo de Dashboard Administrativo
 * @constant {Routes} AdministrativeDashboardRoutes
 * @description Define las rutas disponibles para el módulo de dashboard administrativo
 * 
 */
export const AdministrativeDashboardRoutes: Routes = [
      {
            path: 'inicio',
            loadComponent: () => import('./administrative-dashboards.component')
      },
];
