import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { ManagementDefaultRateListComponent } from "../../components/management-default-rate-list/management-default-rate-list.component";

@Component({
  selector: 'app-management-default-rate-index',
  templateUrl: './management-default-rate-index.component.html',
  standalone: true,
  imports: [CommonModule, BreadcrumbComponent, ManagementDefaultRateListComponent],
})
export class ManagementDefaultRateIndexComponent {
  breadcrumbItems = [
    { label: 'Gestión tasa mora', url: '/empleados/listado' },
  ];
}
