import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustmersCodeOptService {

  private baseUrl: string = environment.apiUrlCustomer + '/codigos-otp/all';

  /**
   * @param {HttpClient} http - El cliente HTTP para realizar peticiones
   */
  constructor(private http: HttpClient) { }

  /**
   * Obtiene los códigos OTP paginados y filtrados
   * @param {number} page - El número de página a obtener
   * @param {string} [filter=''] - El filtro opcional para buscar códigos específicos
   * @returns {Observable<any>} Un Observable con la respuesta del servidor
   */
  getCodesOpt(page: number, filter: string = ''): Observable<any> {
    const url = `${this.baseUrl}?page=${page}&filter=${filter}`;
    return this.http.get<any>(url);
  }

}
