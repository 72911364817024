import { Component, inject, Input } from '@angular/core';
import { TotalDayInterface, TotalMonthInterface } from '../../interfaces/commerce-dashboard.interface';
import { CommerceDashboardService, MetricCategory, MetricType } from '../../services/commerce-dashboard.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sales-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sales-dashboard.component.html',
  styleUrl: './sales-dashboard.component.scss'
})
export class SalesDashboardComponent {

  /** 
   * Datos totales del día o mes que se mostrarán en el dashboard
   * @type {TotalDayInterface | TotalMonthInterface} 
   */
  @Input() data!: TotalDayInterface | TotalMonthInterface;

  /**
   * Título principal del componente
   * @type {string}
   * @default ''
   */
  @Input() title: string = '';

  /**
   * Subtítulo del componente
   * @type {string}
   * @default ''
   */
  @Input() subtitle: string = '';

  /**
   * Tipo de métrica temporal (día o mes)
   * @type {MetricType}
   * @default MetricType.DAY
   */
  @Input() timeType: MetricType = MetricType.DAY;

  /**
   * Clase CSS para el color del componente
   * @type {string}
   * @default 'pink'
   */
  @Input() colorClass: string = 'pink';

  /** Servicio inyectado para el dashboard de comercio */
  private commerceDashboardService = inject(CommerceDashboardService)

  /** Enum de categorías de métricas accesible desde el template */
  protected MetricCategory = MetricCategory;

  /**
   * Calcula el cambio porcentual entre dos valores
   * @param {number} currentValue - Valor actual
   * @param {number} previousValue - Valor anterior
   * @param {MetricCategory} type - Categoría de la métrica
   * @returns {string} Porcentaje de cambio formateado
   */
  public calculateChange(currentValue: number, previousValue: number, type: MetricCategory): string {
    return this.commerceDashboardService.calculateChange(currentValue, previousValue, type, this.timeType);
  }


}
