<app-breadcrumb
[title]="'Inicio'"
[items]="breadcrumbItems"
></app-breadcrumb>
<div class="container-fluid default-dash container-dashboard-main">
  
  

  <section class="d-flex flex-wrap p-2 justify-content-around">
    <app-mini-cards-dashboard class="d-flex flex-wrap justify-content-around cards" [commerceDashboardCard]="dataOfminiCards" ></app-mini-cards-dashboard>
  </section>

  <section class="mt-5 section-ventas d-flex flex-wrap justify-content-around">
      <article class="content-ventas-test">
            <app-sales-dashboard
            [data]="totalDay"
            title="Ventas del Día"
            subtitle="Resumen de ventas"
            [timeType]="MetricType.DAY"
            colorClass="yellow">
          </app-sales-dashboard>
          <app-sales-dashboard
            [data]="totalMonth"
            title="Ventas del Mes"
            subtitle="Resumen de ventas del mes"
            [timeType]="MetricType.MONTH"
            colorClass="pink">
          </app-sales-dashboard>
      </article>

  <article class="article-grafic" >

      <app-coupon-dashboard [dataCoupon]="dataOfCoupon"  [date]="date"></app-coupon-dashboard>
      
      <div class="chart-container">
        <app-spinner-graphic [state]="stateGraphic" ></app-spinner-graphic>
        

        <app-graphic-dashboard  *ngIf="stateGraphic" [dataOfCity]="dataSendCity" ></app-graphic-dashboard>

        <div class="content-buttons" *ngIf="stateGraphic">
          <button
            class="btn btn-primary shadow-sm btn-ziro fw-bold me-2"
            *ngIf="currentPageCity > 1"
            (click)="changeCitysBypagination(currentPageCity - 1)"
          >
          <i class="fa-solid fa-caret-left " style="color: #000000;"></i> Anterior
          </button>
          <button
            class="btn btn-primary shadow-sm btn-ziro  fw-bold me-2"
            *ngIf="currentPageCity < limitCity"
            (click)="changeCitysBypagination(currentPageCity + 1)"
          >
            Siguiente <i class=" fa-solid fa-caret-right " style="color: #000000;"></i>
          </button>
        </div>
        
       
      </div>

  </article>
  
      
  
    </section>

 

</div>
