import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreditHistoryResponseInterface } from '../interfaces/credits-historyCreditsCurtomer-interface';
import { CreditRequestResponse } from '../interfaces/credits-request-interfaces';
import {
      CreditRequestListPaginatedInterface,
      CreditRequestListResponseInterface,
} from '../interfaces/credits-request-list-interface';

@Injectable({
      providedIn: 'root',
})
export class CreditRequestService {
      private baseUrl: string = environment.apiUrl + '/solicitudes-credito';
      private baseUrlNote: string = environment.apiUrl;

      constructor(private http: HttpClient) { }

      /**
       * Obtiene las solicitudes de crédito.
       * @returns {Observable<CreditRequestResponse>} Observable con la respuesta de las solicitudes de crédito.
       */
      getCreditsRequests(): Observable<CreditRequestResponse> {
            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/tarjeta`);
      }

      /**
       * Obtiene una lista paginada de solicitudes de crédito.
       * @param {number} page - Número de página a obtener.
       * @returns {Observable<CreditRequestListPaginatedInterface>} Observable con la lista paginada de solicitudes de crédito.
       */
      getCreditsRequestsList(page: number): Observable<CreditRequestListPaginatedInterface> {
            const url = `${this.baseUrl}?page=${page}`;
            return this.http.get<CreditRequestListPaginatedInterface>(url);
      }

      /**
       * Pasa una solicitud de crédito a estado de estudio.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      passToStudy(creditRequestId: number): Observable<any> {
            return this.http.post(`${this.baseUrl}/pasar-estudio`, { creditRequestId });
      }

      /**
       * Pasa una solicitud de crédito a estado de preaprobado.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      passToPreaproved(creditRequestId: number): Observable<any> {
            return this.http.post(`${this.baseUrl}/pasar-preaprobado`, { creditRequestId });
      }

      /**
       * Pasa una solicitud de crédito a estado de aprobado.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @param {number} valueApproved - Valor aprobado para el crédito.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      passToApproved(creditRequestId: number, valueApproved: number): Observable<any> {
            return this.http.post(`${this.baseUrl}/pasar-aprobado`, { creditRequestId, valueApproved });
      }

      /**
       * Pasa una solicitud de crédito a estado de rechazado.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @param {string} reasonReject - Razón del rechazo.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      passToRejected(creditRequestId: number, reasonReject: string): Observable<any> {
            return this.http.post(`${this.baseUrl}/pasar-rechazado`, { creditRequestId, reasonReject });
      }

      /**
       * Devuelve una solicitud de crédito al estado de estudio.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @param {string} comment - Comentario sobre el retorno a estudio.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      returnToStudy(creditRequestId: number, comment: string): Observable<any> {
            this.getCreditsRequests();
            return this.http.post(`${this.baseUrl}/regresar-estudio`, { creditRequestId, comment });
      }

      /**
       * Guarda una nota del cliente.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @param {string} note - Nota a guardar.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      saveCustomerNote(creditRequestId: number, note: string): Observable<any> {
            return this.http.post(`${this.baseUrlNote}/notas`, { creditRequestId, note });
      }

      /**
       * Guarda comentarios para una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @param {string} comment - Comentario a guardar.
       * @param {string} type - Tipo de comentario.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      saveCommentsCreditRequest(creditRequestId: number, comment: string, type: string): Observable<any> {
            return this.http.post(`${this.baseUrl}/agregar/comentario`, { type, comment, credit_request_id: creditRequestId });
      }

      /**
       * Obtiene los comentarios de una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con los comentarios de la solicitud.
       */
      getCommentsCreditRequest(creditRequestId: number): Observable<any> {
            return this.http.get(`${this.baseUrl}/ver/comentarios/${creditRequestId}`);
      }

      /**
       * Obtiene las notas del cliente para una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con las notas del cliente.
       */
      getCustomerNotes(creditRequestId: number): Observable<any> {
            return this.http.get(`${this.baseUrlNote}/notas/solicitud-credito/${creditRequestId}`);
      }

      /**
       * Obtiene los documentos del cliente para una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con los documentos del cliente.
       */
      getCustomerDocuments(creditRequestId: number): Observable<any> {
            return this.http.get(`${this.baseUrlNote}/documentos/solicitud-credito/${creditRequestId}`);
      }

      /**
       * Obtiene el historial de créditos de un cliente.
       * @param {number} creditCustomerId - ID del cliente.
       * @returns {Observable<CreditHistoryResponseInterface>} Observable con el historial de créditos del cliente.
       */
      getHistoryCreditsCustomers(creditCustomerId: number): Observable<CreditHistoryResponseInterface> {
            return this.http.get<CreditHistoryResponseInterface>(`${this.baseUrlNote}/creditos/clientes/info-mora/${creditCustomerId}`);
      }

      /**
       * Busca solicitudes de crédito con paginación.
       * @param {any} formData - Datos del formulario de búsqueda.
       * @param {number} page - Número de página a obtener.
       * @returns {Observable<CreditRequestListPaginatedInterface>} Observable con la lista paginada de solicitudes de crédito.
       */
      searchCreditsRequests(formData: any, page: number): Observable<CreditRequestListPaginatedInterface> {
            let params = new HttpParams();

            for (const key in formData) {
                  if (formData[key] !== null) {
                        params = params.append(key, formData[key]);
                  }
            }

            return this.http.get<CreditRequestListPaginatedInterface>(`${this.baseUrl}/buscar?page=${page}`, { params });
      }

      /**
       * Busca solicitudes de crédito para exportar.
       * @param {any} formData - Datos del formulario de búsqueda.
       * @returns {Observable<CreditRequestListResponseInterface>} Observable con la lista de solicitudes de crédito para exportar.
       */
      searchCreditsRequestsForExport(formData: any): Observable<CreditRequestListResponseInterface> {
            formData.action = 'exportar';
            const params = new HttpParams({ fromObject: formData });
            return this.http.get<CreditRequestListResponseInterface>(`${this.baseUrl}/buscar`, { params });
      }

      /**
       * Guarda un documento del cliente.
       * @param {any} formdata - Datos del formulario con el documento.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      saveCustomerDocument(formdata: any): Observable<any> {
            return this.http.post(`${this.baseUrl}/guardar/documentos`, formdata);
      }

      /**
       * Devuelve una solicitud de crédito al estado "En solicitud".
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      returnToInRequest(creditRequestId: number): Observable<any> {
            this.getCreditsRequests();
            return this.http.post(`${this.baseUrl}/regresar-a-en-solicitud`, { creditRequestId });
      }

      /**
       * Actualiza la información del cliente.
       * @param {any} customer - Datos actualizados del cliente.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      updateInfoCustomer(customer: any): Observable<any> {
            return this.http.post<any>(`${this.baseUrlNote}/clientes/update-customer`, customer);
      }

      /**
       * Obtiene el historial de una solicitud de crédito.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con el historial de la solicitud.
       */
      getHistoryRequestCredit(creditRequestId: number): Observable<any> {
            return this.http.get<any>(`${this.baseUrl}/history/${creditRequestId}`);
      }

      /**
       * Envía el enlace del pagaré al cliente.
       * @param {number} creditRequestId - ID de la solicitud de crédito.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      sendPagareLink(creditRequestId: number): Observable<any> {
            return this.http.post(`${this.baseUrl}/enviar-pagare/${creditRequestId}`, {});
      }

      /**
       * Descarga un archivo.
       * @param {string} filePath - Ruta del archivo a descargar.
       * @returns {Observable<Blob>} Observable con el archivo descargado como Blob.
       */
      downloadFile(filePath: string): Observable<Blob> {
            return this.http.get(`${this.baseUrl}/download-file/${filePath}`, { responseType: 'blob' });
      }

      /**
       * Verifica la firma del pagaré de un cliente.
       * @param {number} customer_id - ID del cliente.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      verifyPromissoryNoteSignature(customer_id: number): Observable<any> {
            return this.http.get(`${this.baseUrl}/tarjeta/verify-promissory-note-signature/${customer_id}`);
      }

      /**
       * Realiza la aprobación automática de una solicitud de crédito.
       * @param {{ credit_request_id: number; customer_id: number; value_request: number; }} data - Datos necesarios para la aprobación automática.
       * @returns {Observable<any>} Observable con la respuesta del servidor.
       */
      automaticApproval(data: {
            credit_request_id: number;
            customer_id: number;
            value_request: number;
      }) {
            return this.http.put<any>(`${this.baseUrl}/solicitudes-credito/aprobacion/automatica/${data.customer_id}`, data);
      }
}
