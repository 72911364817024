<ng-template #modalCustomersSearch let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Busca el estado actual del cliente!
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeWithModal(modal)"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="customerIdentification">Escribe la cédula del cliente</label>
          <div class="d-flex gap-1">
            <input autofocus class="form-control" placeholder="Cédula del cliente" [(ngModel)]="cupoInfo.customerIdentification" />
            <div class="input-group-append">
              <button class="btn btn-primary h-100" type="button" (click)="searchCustomer()">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        @if ( flagSearchCustomer && !customer) {
          <div class="w-100">
            <h4 class="text-center">
              El cliente aún no está en el sistema
            </h4>
          </div>
        }
       @if (customer) {
        <div>
          <div class="row">
            <div class="col-12 mb-2">
              <h4>Información del Cliente:</h4>
            </div>
          </div>
  
          <!-- total cupo aprobado -->
          <div class="d-flex flex-column">
            <app-customer-quota [totals]="totals" [mora]="mora" [customer]="customer" [role]="role" (editCupo)="customerEditCupo($event)"></app-customer-quota>
            
            <!-- Si el cliente a firmado o no ah firmado el pagare-->
           <app-customer-inactive-or-active [customer]="customer"></app-customer-inactive-or-active> 
  
            <!-- datos del cliente -->
            <div class="d-flex flex-column gap-1 w-100 border border-success border-opacity-25 p-3 rounded">
              <app-customers-data [customer]="customer" [totals]="totals"></app-customers-data>
            </div>
  
            <div class="row mb-3 mt-2" 
              *ngIf="
                totals.cupoDisponibleCompras>0 &&
                !mora &&
                customer.pagare_signed == 'Si' &&
                roleCanWithdrawCreditAction &&
                customer.state == 'Activo'
              "
            >
            <label for="shopCommerce">Selecciona una sucursal</label>
            <div class="d-flex gap-1 w-100">
              <div class="col-7">
                <ng-select [items]="shopsCommerces" bindLabel="name" bindValue="id" id="shopCommerce"  notFoundText="No se encontraron Sucursales" [(ngModel)]="shopCommerceId" placeholder="Seleccionar Sucursal">
                   
                </ng-select>
              </div>
              <button class="btn btn-primary w-100 h-100" (click)="confirmCreditWithdrawal()">
                <i class="fas fa-coins"></i> Solicitar retiro de crédito
              </button>
            </div>
            </div>
          </div>
        </div>
       }
      </div>
    </div>

  </ng-template>
  <!-- modal retiro credito -->
  <app-withdraw-credit />