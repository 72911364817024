import { Routes } from '@angular/router';

export const AuditsRoutes: Routes = [
        {
                path: 'creditos',
                loadComponent: () => import('./pages/credit-audits/credit-audits.component')
        },
        {
                path: 'creditos/:id',
                loadComponent: () => import('./pages/credit-audits/credit-audits.component')
        }
];