import { Routes } from '@angular/router';
import { CustomerServicesParametersRoutes } from './parameters/customer-services-parameters.routes';

export const CustomerServicesRoutes: Routes = [
      {
            path: 'parametros',
            children: CustomerServicesParametersRoutes
      },
      {
            path: 'liwa',
            loadComponent: () => import('./liwa/pages/cs-liwa-index/cs-liwa-index.component')
      },
      {
            path: 'gestion-diaria',
            loadComponent: () => import('./daily-management/pages/cs-daily-management-list-index/cs-daily-management-list-index.component')
      },
      {
            path: 'gestion-diaria/crear',
            loadComponent: () => import('./daily-management/pages/cs-daily-management-create-index/cs-daily-management-create-index.component')
      },
      {
            path: 'whatsapp',
            loadComponent: () => import('./whatsapp/pages/csw-index/csw-index.component')
      }
];
