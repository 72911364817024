import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, Subject, tap } from 'rxjs';
import { CreditLineInterface, CreditLinePaginatedInterface, CreditLineResponseInterface } from '../../../shared/interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class CreditLineService {
    /**
     * URL base para las operaciones de líneas de crédito.
     * @private
     * @readonly
     */
    private readonly baseUrl: string = `${environment.apiUrl}/lineas-credito`;

    /**
     * Subject para notificar actualizaciones en las líneas de crédito.
     * @private
     * @readonly
     */
    private readonly refreshCreditsLines$ = new Subject<void>();
  
    /**
     * Línea de crédito seleccionada actualmente.
     */
    creditslinesSelected!: CreditLineInterface;

    /**
     * Acción actual sobre la línea de crédito (por defecto 'Crear').
     */
    creditslinesAction: string = 'Crear';
  
    /**
     * @param http Cliente HTTP para realizar peticiones.
     */
    constructor(private http: HttpClient) {}
  
    /**
     * Obtiene un Observable para notificaciones de actualización de líneas de crédito.
     * @returns Observable<void>
     */
    get refreshCreditsLines(): Observable<void> {
      return this.refreshCreditsLines$.asObservable();
    }
  
    /**
     * Obtiene las líneas de crédito paginadas.
     * @param page Número de página (por defecto 0).
     * @returns Observable<CreditLinePaginatedInterface>
     */
    getCreditsLines(page: number = 0): Observable<CreditLinePaginatedInterface> {
      const url = page === 0 ? this.baseUrl : `${this.baseUrl}/page/${page}`;
      return this.http.get<CreditLinePaginatedInterface>(url);
    }
  
    /**
     * Almacena una nueva línea de crédito.
     * @param creditslines Datos de la línea de crédito a crear.
     * @returns Observable<CreditLineResponseInterface>
     */
    storeCreditLine(creditslines: CreditLineInterface): Observable<CreditLineResponseInterface> {
      return this.http.post<CreditLineResponseInterface>(this.baseUrl, creditslines)
        .pipe(tap(() => this.refreshCreditsLines$.next()));
    }
  
    /**
     * Obtiene los detalles de una línea de crédito específica.
     * @param id ID de la línea de crédito.
     * @returns Observable<CreditLineResponseInterface>
     */
    showCreditLine(id: number): Observable<CreditLineResponseInterface> {
      return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/${id}`);
    }
  
    /**
     * Actualiza una línea de crédito existente.
     * @param id ID de la línea de crédito a actualizar.
     * @param creditslines Nuevos datos de la línea de crédito.
     * @returns Observable<any>
     */
    updateCreditLine(id: number, creditslines: CreditLineInterface): Observable<any> {
      return this.http.put<any>(`${this.baseUrl}/${id}`, creditslines)
        .pipe(tap(() => this.refreshCreditsLines$.next()));
    }
  
    /**
     * Elimina una línea de crédito.
     * @param id ID de la línea de crédito a eliminar.
     * @returns Observable<any>
     */
    deleteCreditLine(id: number): Observable<any> {
      return this.http.delete<any>(`${this.baseUrl}/${id}`)
        .pipe(tap(() => this.refreshCreditsLines$.next()));
    }
  
    /**
     * Obtiene las líneas de crédito asignadas a una tienda específica.
     * @param shopCommerceId ID de la tienda.
     * @returns Observable<CreditLineResponseInterface>
     */
    getCreditsLinesByShop(shopCommerceId: number): Observable<CreditLineResponseInterface> {
      return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/asignadas/proveedor/sucursal/${shopCommerceId}`);
    }
  
    /**
     * Activa una línea de crédito.
     * @param id ID de la línea de crédito a activar.
     * @returns Observable<CreditLineResponseInterface>
     */
    enableCreditLine(id: number): Observable<CreditLineResponseInterface> {
      return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/activar/${id}`)
        .pipe(tap(() => this.refreshCreditsLines$.next()));
    }
  
    /**
     * Desactiva una línea de crédito.
     * @param id ID de la línea de crédito a desactivar.
     * @returns Observable<CreditLineResponseInterface>
     */
    disableCreditLine(id: number): Observable<CreditLineResponseInterface> {
      return this.http.get<CreditLineResponseInterface>(`${this.baseUrl}/desactivar/${id}`)
        .pipe(tap(() => this.refreshCreditsLines$.next()));
    }
}