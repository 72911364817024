import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { CreditWithArrearInterface } from "../../../../../administrative/credits/interfaces/credits-with-arrears-interface";
import { CustomerInterface } from "../../../../../administrative/customers/interfaces/customer-interface";
import { toggleLoader } from "../../../../helpers/functions-helper.service";
import { ModalServiceAlert } from "../../../modal-alert/service/modal-alert.service";
import { LoaderService } from "../../../../services/loader.service";
import { CreditRequestService } from "../../../../../administrative/credits-requests/services/credit-request.service";
import { DefaultIfEmptyPipe } from "../../../../pipes/defaultEmpty.pipe";

@Component({
      selector: 'app-credits-tab',
      templateUrl: './credits-tab.component.html',
      standalone: true,
      imports: [
            CommonModule,
            DefaultIfEmptyPipe
      ]
})
export class CreditsTabComponent implements OnInit {
      @Input() customerInfo!: CustomerInterface;
      historyCreditsCustomerList!: CreditWithArrearInterface[];

      constructor(
            private loaderService: LoaderService,
            private alertService: ModalServiceAlert,
            private creditRequestService: CreditRequestService,
      ) { }

      /**
       * Inicializa el componente y obtiene el historial de créditos.
       */
      ngOnInit(): void {
            this.getHistoryCredits();
      }

      /**
       * Obtiene el historial de créditos del cliente.
       * Muestra un loader mientras se realiza la petición.
       * En caso de éxito, actualiza la lista de créditos.
       * En caso de error, muestra un mensaje de error.
       */
      getHistoryCredits(): void {
            const customerInfoId = this.customerInfo.id;
            toggleLoader(this.loaderService, true);
            this.creditRequestService.getHistoryCreditsCustomers(customerInfoId).subscribe(
                  (response) => {
                        toggleLoader(this.loaderService, false);
                        this.historyCreditsCustomerList = response.data;
                  },
                  (error) => {
                        toggleLoader(this.loaderService, false);
                        this.alertService.openModal(
                              'Error',
                              'Ocurrió un error al obtener los créditos. Por favor, inténtalo nuevamente.',
                              'error'
                        );
                  }
            );
      }
}