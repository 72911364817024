import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreditRequestResponse } from '../interfaces/credits-request-interfaces';


@Injectable({
      providedIn: 'root',
})
export class CreditRequestCardService {
      private baseUrl = environment.apiUrl + '/solicitudes-credito/tarjeta';
      private refreshCards = new Subject<void>();
      refreshCards$ = this.refreshCards.asObservable();

      constructor(private http: HttpClient) { }

      /**
       * Obtiene las solicitudes de crédito según el tipo, página y parámetros especificados.
       * @param {string} type - El tipo de solicitud de crédito.
       * @param {number} page - El número de página para la paginación.
       * @param {any} params - Parámetros adicionales para la consulta.
       * @returns {Observable<any>} Un Observable con la respuesta de la solicitud.
       */
      getCreditRequestsCard(type: string, page: number, params: any = {}): Observable<any> {
            let queryParams = new HttpParams().set('page', page.toString());

            Object.keys(params).forEach((key: string) => {
                  if (params[key] !== null && params[key] !== undefined) {
                        queryParams = queryParams.set(key, params[key]);
                  }
            });

            const endpoint = this.getEndpoint(type);

            return this.http.get<CreditRequestResponse>(`${this.baseUrl}/${endpoint}`, { params: queryParams });
      }

      /**
       * Obtiene el endpoint correspondiente al tipo de solicitud de crédito.
       * @param {string} type - El tipo de solicitud de crédito.
       * @returns {string} El endpoint correspondiente al tipo de solicitud.
       * @throws {Error} Si el tipo de solicitud no es soportado.
       * @private
       */
      private getEndpoint(type: string): string {
            switch (type) {
                  case 'received':
                        return 'received';
                  case 'preaproved':
                        return 'preaproved';
                  case 'study':
                        return 'study';
                  case 'managed':
                        return 'smanaged';
                  default:
                        throw new Error(`Unsupported credit request type: ${type}`);
            }
      }

      /**
       * Emite un evento para refrescar las tarjetas de solicitud de crédito.
       */
      emitRefreshCards() {
            this.refreshCards.next();
      }
}