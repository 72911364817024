import { Component, HostListener } from '@angular/core';
import { Menu } from '../../interfaces/menu.inteface';
import { NavService } from '../../services/nav.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [ RouterModule, CommonModule ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  
  /** @property {Menu[]} menuItems - Array de items del menú. */
  public menuItems: Menu[] = [];

  /** @property {boolean} myBtnIdClicked - Indica si un botón específico ha sido clickeado. */
  public myBtnIdClicked: boolean = false;

  /** @property {number} margin - Margen para el diseño del menú. */
  public margin: number = 0;

  /** @property {number} width - Ancho actual de la ventana. */
  public width: number = window.innerWidth;

  /** @property {boolean} leftArrowNone - Indica si la flecha izquierda debe ocultarse. */
  public leftArrowNone: boolean = true;

  /** @property {boolean} rightArrowNone - Indica si la flecha derecha debe ocultarse. */
  public rightArrowNone: boolean = false;

  /**
   * @constructor
   * @param {NavService} navService - Servicio para manejar la navegación.
   * @param {Router} router - Servicio de enrutamiento de Angular.
   */
  constructor(
    private navService: NavService,
    private router: Router,
  ) {
    this.subscribeToMenuItems();
    this.handleNavigationEnd();
  }

  /**
   * @method onResize
   * @description Maneja el evento de redimensionamiento de la ventana.
   * @param {Event} event - Evento de redimensionamiento.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
  }

  /**
   * @method subscribeToMenuItems
   * @private
   * @description Se suscribe a los cambios en los items del menú.
   */
  private subscribeToMenuItems(): void {
    this.navService.items.subscribe(menuItems => {
      this.menuItems = menuItems;
    });
  }

  /**
   * @method handleNavigationEnd
   * @private
   * @description Maneja el evento de finalización de la navegación.
   */
  private handleNavigationEnd(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenuItem(event.url);
      }
    });
  }

  /**
   * @method setActiveMenuItem
   * @private
   * @description Establece el ítem del menú activo basado en la URL actual.
   * @param {string} url - URL actual.
   */
  private setActiveMenuItem(url: string): void {
    this.menuItems.forEach(item => this.checkMenuItem(item, url));
  }

  /**
   * @method checkMenuItem
   * @private
   * @description Comprueba si un ítem o subítem coincide con la URL actual.
   * @param {Menu} item - Ítem del menú a comprobar.
   * @param {string} url - URL actual.
   */
  private checkMenuItem(item: Menu, url: string): void {
    if (item.path === url) {
      this.setNavActive(item);
      this.activateParentIfChild(item);
    }
    if (item.children) {
      item.children.forEach(subItem => {
        this.checkMenuItem(subItem, url);
        if (subItem.path === url) {
          this.setNavActive(subItem);
          item.active = true;
        }
      });
    }
  }

  /**
   * @method activateParentIfChild
   * @private
   * @description Activa el padre de un ítem hijo seleccionado.
   * @param {Menu} selectedItem - Ítem hijo seleccionado.
   */
  private activateParentIfChild(selectedItem: Menu): void {
    this.menuItems.forEach(menuItem => {
      if (menuItem.children) {
        if (menuItem.children.some(child => child === selectedItem)) {
          menuItem.active = true;
        }
      }
    });
  }

  /**
   * @method setNavActive
   * @private
   * @description Activa un ítem de menú y desactiva los demás.
   * @param {Menu} item - Ítem del menú a activar.
   */
  private setNavActive(item: Menu): void {
    this.menuItems.forEach(menuItem => {
      menuItem.active = menuItem === item;
      if (menuItem.children) {
        menuItem.children.forEach(submenuItem => {
          submenuItem.active = submenuItem === item;
        });
      }
    });
  }

  /**
   * @method toggleNavActive
   * @public
   * @description Alterna el estado activo de un ítem del menú y navega a su ruta si existe.
   * @param {Menu} item - Ítem del menú a alternar.
   */
  public toggleNavActive(item: Menu): void {
    if (item.children && item.children.length > 0) {
      item.active = !item.active;
    } else {
      item.active = true;
      if (item.path) {
        this.router.navigateByUrl(item.path);
      }
    }
    this.deactivateOtherItems(item);
  }

  /**
   * @method deactivateOtherItems
   * @private
   * @description Desactiva otros ítems cuando uno es activado.
   * @param {Menu} activeItem - Ítem activo del menú.
   */
  private deactivateOtherItems(activeItem: Menu): void {
    this.menuItems.forEach(item => {
      if (item !== activeItem) {
        if (!this.isChildActive(item, activeItem)) {
          item.active = false;
        }
        if (item.children) {
          item.children.forEach(subItem => {
            if (subItem !== activeItem) {
              subItem.active = false;
            }
          });
        }
      }
    });
  }

  /**
   * @method isChildActive
   * @private
   * @description Comprueba si un ítem es hijo de otro.
   * @param {Menu} parent - Ítem padre.
   * @param {Menu} activeItem - Ítem activo.
   * @return {boolean} - True si el ítem activo es hijo del ítem padre, false en caso contrario.
   */
  private isChildActive(parent: Menu, activeItem: Menu): boolean {
    return parent.children?.some(child => child === activeItem) || false;
  }

  /**
   * @method sidebarToggle
   * @public
   * @description Alterna el colapso de la barra lateral.
   */
  public sidebarToggle(): void {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
  }
    

}
