import { Routes } from '@angular/router';

export const PaymentsAlliesRoutes: Routes = [
    {
          path: 'proveedores-listado',
          loadComponent: () => import('./payments-allies.component')
    },
    {
          path: 'proveedores-pagos/crear',
          loadComponent: () => import('./pages/payments-allies-create/payments-allies-create.component')
    },
    {
          path: 'proveedores-pagos/:id',
          loadComponent: () => import('./pages/payments-allies-detail/payments-allies-detail.component')
    },
];
