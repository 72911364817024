import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CityLabel, SalesCity } from '../../interfaces/commerce-dashboard.interface';
import { Chart, ChartData, ChartEvent, ChartType, BarController, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-graphic-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './graphic-dashboard.component.html',
  styleUrl: './graphic-dashboard.component.scss'
})
export class GraphicDashboardComponent {

  /** Instancia del gráfico */
  public chart!: Chart;

  /** Datos de ventas por ciudad recibidos como Input */
  @Input() public dataOfCity!: SalesCity;

  /** 
   * Colores utilizados para el gráfico
   * @private
   * @readonly
   * @type {string[]}
   */
  private readonly CHART_COLORS = [
    "rgb(255, 153, 51)",
    "rgb(102, 204, 255)", 
    "rgb(255, 153, 51)",
    "rgb(102, 204, 255)",
    "rgb(255, 102, 102)",
    "rgb(51, 153, 102)",
    "rgb(204, 102, 255)",
    "rgb(255, 204, 51)",
    "rgb(153, 51, 255)",
    "rgb(0, 153, 204)",
    "rgb(255, 51, 153)",
    "rgb(51, 204, 153)",
    "rgb(204, 0, 102)",
    "rgb(0, 204, 204)",
    "rgb(255, 102, 204)",
    "rgb(102, 255, 102)",
    "rgb(255, 153, 204)"
  ];

  /**
   * Configuración general del gráfico
   * @private
   * @readonly
   * @type {{fontFamily: string, color: string, titleText: string, titleSize: number, legendBoxWidth: number}}
   */
  private readonly CHART_CONFIG = {
    fontFamily: "Poppins",
    color: "#1E2F65", 
    titleText: "Ciudades con mayores ventas",
    titleSize: 20,
    legendBoxWidth: 30
  };

  /**
   * Media queries para responsive design
   * @private
   * @readonly
   * @type {{mobile: string, tablet: string}}
   */
  private readonly MEDIA_QUERIES = {
    mobile: "(max-width: 669px)",
    tablet: "(max-width: 880px)"
  };

  /**
   * Datos formateados para el gráfico
   * @private
   * @type {ChartData<'bar' | 'doughnut'>}
   */
  private data: ChartData<'bar' | 'doughnut'> = {
    labels: [] as CityLabel[],
    datasets: [{
      label: "Ventas",
      data: [],
      backgroundColor: this.CHART_COLORS,
      borderWidth: 0
    }]
  };

  /**
   * Constructor del componente
   * @param {Router} router - Servicio de enrutamiento de Angular
   */
  constructor(private router: Router) {
    Chart.register(BarController, BarElement, CategoryScale, LinearScale);
  }

  /**
   * Inicializa el gráfico después de que la vista se haya cargado
   */
  ngAfterViewInit(): void {
    if (!this.isValidData()) return;
    this.updateChartData();
    this.initializeChart();
  }

  /**
   * Verifica si los datos recibidos son válidos
   * @private
   * @returns {boolean} - Verdadero si los datos son válidos
   */
  private isValidData(): boolean {
    return !!(this.dataOfCity?.label?.length && this.dataOfCity?.data?.length);
  }

  /**
   * Actualiza los datos del gráfico con la información recibida
   * @private
   */
  private updateChartData(): void {
    this.data.labels = this.dataOfCity.label.map((cityName, index) => ({
      id: this.dataOfCity.id[index],
      name: cityName
    }));
    this.data.datasets[0].data = this.dataOfCity.data;
  }

  /**
   * Inicializa el gráfico y configura los listeners para responsive
   * @private
   */
  private initializeChart(): void {
    const mediaQueries = {
      mobile: window.matchMedia(this.MEDIA_QUERIES.mobile),
      tablet: window.matchMedia(this.MEDIA_QUERIES.tablet)
    };

    const updateChart = () => {
      const chartType = this.getChartTypeForScreenSize(mediaQueries);
      this.renderChart(chartType.type, chartType.axis);
    };

    Object.values(mediaQueries).forEach(query => 
      query.addEventListener('change', updateChart)
    );

    updateChart();
  }

  /**
   * Determina el tipo de gráfico según el tamaño de pantalla
   * @private
   * @param {Record<string, MediaQueryList>} mediaQueries - Media queries actuales
   * @returns {{ type: string, axis: 'x' | 'y' }} - Configuración del tipo de gráfico
   */
  private getChartTypeForScreenSize(mediaQueries: Record<string, MediaQueryList>): { type: string; axis: 'x' | 'y' } {
    if (mediaQueries['mobile'].matches) {
      return { type: 'doughnut', axis: 'x' };
    }
    if (mediaQueries['tablet'].matches) {
      return { type: 'bar', axis: 'x' };
    }
    return { type: 'bar', axis: 'y' };
  }

  /**
   * Renderiza el gráfico con la configuración especificada
   * @private
   * @param {string} type - Tipo de gráfico
   * @param {'x' | 'y'} axis - Eje principal del gráfico
   */
  private renderChart(type: string, axis: 'x' | 'y'): void {
    this.destroyExistingChart();
    
    const cityLabels = (this.data.labels as CityLabel[])?.map(city => city.name);
    this.setChartDefaults();

    this.chart = new Chart("chart", {
      type: type as ChartType,
      data: {
        labels: cityLabels,
        datasets: this.data.datasets
      },
      options: this.getChartOptions(axis)
    });
  }

  /**
   * Destruye el gráfico existente si lo hay
   * @private
   */
  private destroyExistingChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  /**
   * Establece los valores por defecto del gráfico
   * @private
   */
  private setChartDefaults(): void {
    Chart.defaults.font.family = this.CHART_CONFIG.fontFamily;
    Chart.defaults.color = this.CHART_CONFIG.color;
  }

  /**
   * Obtiene las opciones de configuración del gráfico
   * @private
   * @param {'x' | 'y'} axis - Eje principal del gráfico
   * @returns {Object} - Opciones de configuración
   */
  private getChartOptions(axis: 'x' | 'y') {
    return {
      responsive: true,
      indexAxis: axis,
      plugins: {
        title: {
          display: true,
          text: this.CHART_CONFIG.titleText,
          font: { size: this.CHART_CONFIG.titleSize }
        },
        legend: {
          labels: { boxWidth: this.CHART_CONFIG.legendBoxWidth }
        }
      },
      onClick: this.handleChartClick.bind(this)
    };
  }

  /**
   * Maneja el evento de clic en el gráfico
   * @private
   * @param {ChartEvent} event - Evento del gráfico
   * @param {any[]} items - Elementos seleccionados
   */
  private handleChartClick(event: ChartEvent, items: any[]): void {
    if (items.length > 0) {
      const cityId = (this.data.labels as CityLabel[])[items[0].index].id;
      this.router.navigate(['creditos/listado', cityId]);
    }
  }

}
