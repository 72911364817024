import { Component, input, InputSignal, output, Output, OutputEmitterRef } from '@angular/core';
import { CustomerHistorialInterface, TotalCreditInterface } from '../../../../../interfaces';
import { CommonModule, DecimalPipe } from '@angular/common';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-customer-quota',
  standalone: true,
  imports: [ DecimalPipe, CommonModule],
  templateUrl: './customer-quota.component.html',
  styleUrl: './customer-quota.component.scss'
})
export class CustomerQuotaComponent {
  
  /**
   * @property {InputSignal<CustomerHistorialInterface>} customer - Señal de entrada requerida que contiene la información del cliente.
   */
  customer: InputSignal<CustomerHistorialInterface> = input.required<CustomerHistorialInterface>();

  /**
   * @property {InputSignal<TotalCreditInterface>} totals - Señal de entrada requerida que contiene los totales de crédito del cliente.
   */
  totals: InputSignal<TotalCreditInterface> = input.required<TotalCreditInterface>();

  /**
   * @property {InputSignal<boolean>} mora - Señal de entrada requerida que indica si el cliente está en mora.
   */
  mora: InputSignal<boolean> = input.required<boolean>();

  /**
   * @property {InputSignal<number>} role - Señal de entrada requerida que contiene el rol del usuario.
   */
  role: InputSignal<number> = input.required<number>();

  /**
   * @property {OutputEmitterRef<CustomerHistorialInterface>} editCupo - Emisor de salida para editar el cupo del cliente.
   */
  editCupo: OutputEmitterRef<CustomerHistorialInterface> = output<CustomerHistorialInterface>();

  /**
   * @method customerEditCupo
   * @description Emite el evento para editar el cupo del cliente actual.
   */
  customerEditCupo() {
    this.editCupo.emit(this.customer());
  }


}
