import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/login/services/auth.service';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

export const tokenInterceptor: HttpInterceptorFn = (request, next) => {
        const authService = inject(AuthService);
        const router = inject(Router);
        const token = authService.getToken();

        // Si la URL termina con '/auth', la petición se maneja sin modificación
        if (request.url.endsWith('/auth')) {
                return next(request);
        }


        // Comprobar si la URL de la petición comienza con la URL de la API deseada
        if (token && (
                request.url.startsWith('https://zirov2back.somosziro.com/api') ||
                request.url.includes('https://desarrollo.somosziro.com:8000/api') ||
                request.url.includes('http://54.232.75.88:8000/api') ||
                request.url.includes('http://127.0.0.1:8000') ||
                request.url.includes('localhost') ||
                request.url.includes('http://64.23.161.235') ||
                request.url.includes('https://api-internal.develop.somosziro.com/api')
        )) {
                request = request.clone({
                        setHeaders: {
                                Authorization: `Bearer ${token}`
                        }
                });
        }

        return next(request).pipe(
                catchError((error) => {
                        if (error.status === 401 && error.error && error.error.message === 'No autenticado.') {
                                // Eliminar todo del LocalStorage
                                localStorage.clear();
                                // Redirigir al usuario a la página de inicio de sesión
                                router.navigate(['/iniciar-sesion']);
                        }
                        return throwError(error);
                })
        );
};
