import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TotalShopQuotaInterface } from '../../interfaces/commerce-dashboard.interface';


interface CouponCard {
  title: string;
  dataKey: 'shop_total_approved_quota' | 'shop_total_available_quota';
  colorClass?: string;
}

@Component({
  selector: 'app-coupon-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './coupon-dashboard.component.html',
  styleUrl: './coupon-dashboard.component.scss'
})
export class CouponDashboardComponent {

  /** 
   * Datos del cupón que contiene información sobre cupos aprobados y disponibles
   * @type {TotalShopQuotaInterface}
   */
  @Input() public dataCoupon!: TotalShopQuotaInterface

  /**
   * Fecha asociada al cupón
   * @type {string} 
   */
  @Input() public date!: string

  /**
   * Configuración de las tarjetas de cupones que se mostrarán
   * Cada tarjeta contiene un título y una clave para acceder a los datos
   * @type {CouponCard[]}
   */
  public couponCards: CouponCard[] = [
    {
      title: 'Total cupo aprobado',
      dataKey: 'shop_total_approved_quota'
    },
    {
      title: 'Total cupo disponible',
      dataKey: 'shop_total_available_quota',
      colorClass: 'color-two'
    }
  ];

  /**
   * Convierte un número en formato string a número
   * @param {string | null | undefined} number - Número en formato string que puede contener comas
   * @returns {number} - Número convertido o 0 si el input es inválido
   */
  public converterNumber(number: string | null | undefined): number {
    if (!number) {
      return 0; 
    }
    return parseFloat(number.replace(/,/g, ''));
  }

}